import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Stack,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { setSnackbarOpen, updateForgotPassword } from "src/redux/actions";
import { useParams, useNavigate } from "react-router";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import CustomInput from "src/components/CustomInput";

const Regex = /^[^/<>'"]*$/;

function checkPassword(password) {
  // Panjang minimal 6 karakter
  if (password.length < 6) {
    return false;
  }

  // Memeriksa apakah termasuk angka, huruf, atau karakter khusus
  var hasNumber = /\d/.test(password);
  var hasLetter = /[a-zA-Z]/.test(password);
  var hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(
    password
  );
  if (!(hasNumber && hasLetter && hasSpecialCharacter)) {
    return false;
  }
  // Kata sandi memenuhi semua kondisi
  return true;
}

const ResetPasswordForm = () => {
  const [form, setForm] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConf, setShowPasswordConf] = useState(false);
  const [validate, setValidate] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const copyForm = { ...form };
    copyForm[e.target.name] = e.target.value;
    setForm(copyForm);
  };

  const handleSubmit = async () => {
    setValidate(true);
    const con = !form.password || !form.password_conf;
    if (con) return;
    if (form.password !== form.password_conf) return;
    if (!checkPassword(form.password)) return;
    const resSubmit = await updateForgotPassword({
      token: params.token,
      password: form.password,
    });
    if (resSubmit.code === 200) {
      setSnackbarOpen("success", "Berhasil Reset Password");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      setSnackbarOpen("error", resSubmit?.data?.message);
    }
  };

  return (
    <Grid container sx={{ mt: "10vh" }}>
      <Stack sx={{ width: "100%", maxWidth: "620px", m: "auto" }}>
        <Typography variant="h4">Reset Password</Typography>
        <CustomInput
          label="Kata Sandi Baru"
          name="password"
          type={showPassword ? "text" : "password"}
          onChange={handleChange}
          value={form.password}
          error={
            (validate && !form.password) ||
            (validate && !checkPassword(form.password))
          }
          helperText={
            validate && !form.password
              ? "* Kata sandi harap diisi"
              : validate && !checkPassword(form.password)
              ? "* Masukkan kombinasi minimal enam karakter terdiri dari angka, huruf, dan tanda baca"
              : ""
          }
          // helperText={
          //   validate && !form.password && "* Kata sandi harap diisi"
          // }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? (
                    <Visibility color="primary" />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <CustomInput
          label="Konfirmasi Kata Sandi"
          name="password_conf"
          type={showPasswordConf ? "text" : "password"}
          onChange={handleChange}
          value={form.password_conf}
          error={validate && !form.password_conf}
          helperText={
            <Stack
              direction="column"
              sx={{
                color: "#d32f2f",
                fontSize: "12px",
                lineHeight: "18px",
                letterSpacing: "0.02em",
              }}
            >
              <Typography sx={{ fontSize: "inherit" }}>
                {validate &&
                  !form.password_conf &&
                  "* Konfirmasi Kata sandi harap diisi"}
              </Typography>
              <Typography sx={{ fontSize: "inherit" }}>
                {validate &&
                  form.password !== form.password_conf &&
                  "* Password tidak sesuai"}
              </Typography>
            </Stack>
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPasswordConf(!showPasswordConf)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPasswordConf ? (
                    <Visibility color="primary" />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          sx={{ m: "auto", mt: "28px" }}
          onClick={handleSubmit}
        >
          {"Simpan"}
        </Button>
      </Stack>
    </Grid>
  );
};

export default ResetPasswordForm;
