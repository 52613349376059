import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  IconButton,
  styled,
  CircularProgress,
  DialogActions,
  Stack,
  Grid,
  Tooltip,
  Paper,
  Alert,
} from "@mui/material";
import {
  getJpExtensionCnForm,
  updateJpExtensionCn,
  updateJpExtensionCnForm,
  uploadFileJp,
  setSnackbarOpen,
  updateCnReimburse,
} from "src/redux/actions";
import { Delete, Download, Remove, Upload } from "@mui/icons-material";
import { DropzoneArea } from "material-ui-dropzone";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import CustomInput from "../CustomInput";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
    // maxWidth: "350px",
  },
}));

const ModalUploadReimburse = ({ open, onClose, dataDetail }) => {
  const globalUrl =
    process.env.NODE_ENV !== "production"
      ? "http://localhost:5000"
      : window.location.origin;
  useTheme();
  const [loading, setLoading] = useState("content");
  const [inputPath, setInputPath] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading("content");
    // const res = await getJpExtensionCnForm({
    //   candidate_id: dataDetail.candidate_id,
    // });
    // setData(res.data);
    setLoading("");
  };

  const handleUpload = async (d, key) => {
    setLoadingButton(true);
    const resUpload = await uploadFileJp(d.target.files[0]);
    if (typeof resUpload === "string") {
      setLoadingButton(false);
      setSnackbarOpen("error", resUpload);
      return;
    }
    setInputPath(resUpload.data.path);
    setLoadingButton(false);
  };

  const handleDownload = () => {
    setTimeout(() => {
      var url = globalUrl + "/" + dataDetail?.ref_file_path;
      var link = document.createElement("a");
      link.href = url;
      link.click();
    }, 500);
  };

  const handleSendReimburse = async () => {
    dataDetail.input_file_path = inputPath;
    dataDetail.update_type = "upload-reimburse";
    setLoading("buttonApply");
    await updateCnReimburse(dataDetail);
    setLoading("");
    onClose();
  };

  const handleBlur = (e) => {
    dataDetail[e.target.name] = e.target.value;
  };

  if (loading === "content") return <CircularProgress />;

  return (
    <CustomDialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <b>Upload Reimburse</b>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              border: "1px solid",
              borderRadius: "6px",
              p: "0.5rem 1rem",
              mb: "1rem",
            }}
          >
            <Typography>{dataDetail?.ref_file_name}</Typography>
            <Button variant="contained" size="small" onClick={handleDownload}>
              Download
            </Button>
          </Stack>
          {dataDetail?.validation_notes && (
            <Alert severity="info" sx={{ mb: "1rem" }}>
              {dataDetail?.validation_notes}
            </Alert>
          )}
          <CustomInput
            label="No Rekening"
            name="account_number"
            onBlur={handleBlur}
          />
          <CustomInput
            label="Nama Rekening"
            name="account_name"
            onBlur={handleBlur}
          />
          <CustomInput label="Nama Bank" name="bank_name" onBlur={handleBlur} />
          {/* <DropzoneArea
            filesLimit={1}
            onChange={(e) => handleUpload(e)}
            acceptedFiles={["application/pdf"]}
            showPreviews={false}
          /> */}
          <input
            accept="application/pdf"
            style={{ display: "none" }}
            id="upload-file"
            type="file"
            onChange={(d) => handleUpload(d)}
          />
          <label htmlFor={"upload-file"} style={{ width: "fit-content" }}>
            <Button
              variant="contained"
              component="span"
              sx={{ height: "40px" }}
              startIcon={
                loadingButton ? <CircularProgress /> : <FileUploadRoundedIcon />
              }
              disabled={loadingButton}
            >
              Upload File
            </Button>
          </label>
          {inputPath && (
            <>
              <Typography variant="h6" sx={{ mt: "1rem" }}>
                Preview
              </Typography>
              <embed
                src={globalUrl + "/" + inputPath}
                width={"100%"}
                height={"500px"}
              />
            </>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Batal</Button>
        {loading === "buttonApply" ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            onClick={handleSendReimburse}
            disabled={loading === "buttonApply"}
          >
            Simpan & Kirim
          </Button>
        )}
      </DialogActions>
    </CustomDialog>
  );
};

export default ModalUploadReimburse;
