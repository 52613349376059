import React from "react";
import MaterialTable from "material-table";
import {
  Search,
  SaveAlt,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  Clear,
  Check,
  FilterList,
  Remove,
  ExpandLess,
  Add,
  Delete,
  Edit,
} from "@mui/icons-material";

const TableMaterial = ({
  columns,
  data,
  title,
  actions,
  disableSearch,
  pageSize = 10,
  selection,
  grouping,
  defaultExpanded,
  font,
  ...props
}) => {
  return (
    <MaterialTable
      icons={{
        ResetSearch: Clear,
        Check: Check,
        DetailPanel: ChevronRight,
        Export: SaveAlt,
        Filter: FilterList,
        FirstPage: FirstPage,
        LastPage: LastPage,
        NextPage: ChevronRight,
        PreviousPage: ChevronLeft,
        Search: Search,
        ThirdStateCheck: Remove,
        SortArrow: ExpandLess,
        Add: Add,
        Delete: Delete,
        Clear: Clear,
        Edit: Edit,
      }}
      components={{
        Container: (props) => (
          <div
            style={{
              backgroundColor: "#ffff",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              borderRadius: "0.75rem",
              position: "unset",
            }}
          >
            {props.children}
          </div>
        ),
      }}
      columns={columns}
      data={data}
      title={title}
      actions={actions}
      options={{
        padding: "dense",
        // actionsColumnIndex: -1,
        headerStyle: {
          color: "black",
          fontWeight: "bold",
          fontFamily: font,
          fontSize: "14px",
        },
        cellStyle: { fontSize: "14px", fontFamily: font },
        search: disableSearch ? false : true,
        pageSize: pageSize,
        pageSizeOptions: [5, 10, 20],
        emptyRowsWhenPaging: false,
        selection: selection || false,
        grouping: grouping || false,
        defaultExpanded: defaultExpanded || false,
        showFirstLastPageButtons: false,
        // tableLayout: "fixed",
      }}
      {...props}
    />
  );
};

export default TableMaterial;
