import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  Stack,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomInput from "src/components/CustomInput";
import { setSnackbarOpen, getIllness, createIllness } from "src/redux/actions";
import { VaccinesOutlined } from "@mui/icons-material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const NewConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderTopWidth: 4,
    borderRadius: 5,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <VaccinesOutlined />,
    2: <VaccinesOutlined />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ["Orang Tua", "Pribadi"];

const Illness = ({ handleChange }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataFactor, setDataFactor] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [loadingCheck, setLoadingCheck] = useState({});
  const validate = true;
  const validates = JSON.parse(localStorage.getItem("validates"));
  const val = parseInt(localStorage.getItem("value"));

  useEffect(() => {
    validates[val + 1] = true;
    localStorage.setItem("validates", JSON.stringify(validates));
    fetchInfo();
  }, [activeStep]);

  const fetchInfo = async () => {
    const resIllness = await getIllness();
    let newIllness = [];
    if (activeStep === 0) {
      newIllness = resIllness.data?.filter((i) => i.is_illness_parent);
    } else {
      newIllness = resIllness.data?.filter((i) => i.is_illness_personal);
      const factor = resIllness.data?.filter((i) => i.is_illness_factor);
      factor?.map((f) => {
        // vaksin
        if (f.illness_id === "29") {
          if (!f.value_select) {
            f.value_select = "1";
          }
        }
        // buta warna
        if (f.illness_id === "30") {
          if (!f.value_select) {
            f.value_select = "2";
          }
        }
        // tato
        if (f.illness_id === "31") {
          if (!f.value_select) {
            f.value_select = "2";
          }
        }
        // tindik
        if (f.illness_id === "32") {
          if (!f.value_select) {
            f.value_select = "2";
          }
        }
        // disabilitas
        if (f.illness_id === "33") {
          if (!f.value_select) {
            f.value_select = "2";
          }
        }
      });

      setDataFactor(factor);
    }
    if (newIllness.illness_id === "21") {
      // penglihatan
      if (!newIllness.value_select) {
        newIllness.value_select = "none";
      }
    }

    setData(newIllness);
    setLoading(false);
  };

  const handleStep = (step) => () => {
    setLoading(true);
    setActiveStep(step);
  };

  const handleCheck = async (val, key, d) => {
    setLoadingCheck(d);
    d[key] = val;
    await createIllness(d);
    setLoadingCheck({});
    setSnackbarOpen("success", "Berhasil Update Data");
    fetchInfo();
  };

  const handleBlur = async (val, key, d) => {
    // console.log({ val, d });
    d[key] = val;
    await createIllness(d);
    setSnackbarOpen("success", "Berhasil Update Data");
    fetchInfo();
  };

  const handleSelectAuto = async (val, key, d) => {
    // ini vaksin
    setLoadingCheck(key);
    if (key === "29") {
      d.is_personal = true;
      if (val.id === "1") d.is_personal = false;
      d.value_select = val.id;
    } else {
      // selain vaksin
      // gangguan penglihatan
      if (key === "21") {
        d.value_select = val.id;
        d.is_personal = true;
        if (val.id === "none") d.is_personal = false;
      } else {
        d.is_personal = true;
        if (val.id === "2") d.is_personal = false;
        d.value_select = val.id;
      }
    }
    await createIllness(d);
    setLoadingCheck("");
    setSnackbarOpen("success", "Berhasil Update Data");
    fetchInfo();
  };

  const handleMoveNext = (e) => {
    const dParent = [...data].filter((d) => d.is_illness_parent === true);
    const dPersonal = [...data].filter((d) => d.is_illness_personal === true);
    const dFactor = [...dataFactor].filter((d) => d.type_form === "boolean");
    let found = false;
    for (let i = 0; i < dParent.length; i++) {
      const d = dParent[i];
      if (
        d.is_father === true ||
        d.is_father === false ||
        d.is_mother === true ||
        d.is_mother === false
      ) {
        found = false;
      } else {
        found = true;
        break;
      }
    }
    for (let i = 0; i < dPersonal.length; i++) {
      const d = dPersonal[i];
      if (d.is_personal === true || d.is_personal === false) {
        found = false;
      } else {
        found = true;
        break;
      }
    }
    for (let i = 0; i < dFactor.length; i++) {
      const d = dFactor[i];
      if (d.is_personal === true || d.is_personal === false) {
        found = false;
      } else {
        found = true;
        break;
      }
    }

    if (found) return;
    handleChange(e, val + 1);
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Riwayat Kesehatan</b>
      </Typography>
      <Stack sx={{ m: "auto", maxWidth: "460px" }}>
        <Stepper nonLinear activeStep={activeStep} connector={<NewConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Stack>

      <Typography sx={{ mt: "2rem", fontSize: "14px" }}>
        Klik tombol jika memiliki riwayat kesehatan seperti berikut
      </Typography>
      {activeStep === 0 && (
        <Stack>
          {data.map((d) => (
            <Grid container sx={{ mb: "1rem" }}>
              <Grid item xs={3} sx={{ m: "auto 0" }}>
                <Typography>{d.illness_name}</Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ m: "auto 0", display: "flex" }}
                spacing={2}
              >
                {/* {["is_father", "is_mother"].map((p) => (
                  <FormControlLabel
                    control={
                      loadingCheck.illness_id === d.illness_id ? (
                        <CircularProgress size={24} sx={{ mr: "1rem" }} />
                      ) : (
                        <Checkbox
                          checked={d[p]}
                          onClick={(e) => handleCheck(e.target.checked, p, d)}
                        />
                      )
                    }
                    label={p === "is_father" ? "Ayah" : "Ibu"}
                  />
                ))} */}
                {["is_father", "is_mother"].map((p) => (
                  <Stack
                    direction="row"
                    key={p}
                    sx={{ ml: p === "is_mother" ? "1rem" : 0 }}
                  >
                    <Typography
                      sx={{
                        mr: "1rem",
                        color:
                          d[p] === true || d[p] === false
                            ? "inherit"
                            : "#ff0000",
                      }}
                    >
                      {p === "is_father" ? "Ayah" : "Ibu"}
                    </Typography>
                    <Stack>
                      {["Ya", "Tidak"].map((o) => (
                        <FormControlLabel
                          control={
                            loadingCheck.illness_id === d.illness_id ? (
                              <CircularProgress size={24} sx={{ mr: "1rem" }} />
                            ) : (
                              <Checkbox
                                sx={{ p: 0 }}
                                checked={
                                  o === "Ya"
                                    ? d[p] === true
                                    : o === "Tidak"
                                    ? d[p] === false
                                    : null
                                }
                                onClick={(e) =>
                                  handleCheck(o === "Ya" ? true : false, p, d)
                                }
                              />
                            )
                          }
                          label={o}
                        />
                      ))}
                    </Stack>
                  </Stack>
                ))}
              </Grid>
              {d.is_desc && (
                <Grid item xs={5} sx={{ m: "auto 0" }}>
                  <CustomInput
                    label=""
                    multiline
                    rows={2}
                    style={{ marginBottom: "0px" }}
                    onBlur={(e) => handleBlur(e.target.value, "parent_desc", d)}
                    defaultValue={d.parent_desc}
                  />
                </Grid>
              )}
            </Grid>
          ))}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%", my: "1.5rem" }}
          >
            <Button
              variant="outlined"
              sx={{
                border: "none",
                opacity: validates[val - 1] ? 1 : 0,
              }}
              onClick={(e) => handleChange(e, val - 1)}
              disabled={!validates[val - 1]}
              startIcon={<KeyboardArrowLeft />}
            >
              Pernyataan Pribadi
            </Button>
            <Button variant="contained" onClick={handleStep(1)}>
              Lanjut
            </Button>
          </Stack>
        </Stack>
      )}

      {activeStep === 1 && (
        <>
          <Stack>
            {data.map((p) => (
              <Grid container spacing={2} sx={{ mb: "1rem" }}>
                <Grid item xs={6} sx={{ m: "auto 0" }}>
                  <Grid container>
                    <Grid item sm={12} md={7} sx={{ m: "auto" }}>
                      <Typography
                        sx={{
                          color:
                            p.is_personal === true || p.is_personal === false
                              ? "inherit"
                              : "#ff0000",
                        }}
                      >
                        {p.illness_name}
                      </Typography>
                    </Grid>
                    <Grid item sm={12} md={5}>
                      <Stack direction="row" spacing={2}>
                        {["Ya", "Tidak"].map((o) => (
                          <FormControlLabel
                            control={
                              loadingCheck.illness_id === p.illness_id ? (
                                <CircularProgress
                                  size={24}
                                  sx={{ mr: "1rem" }}
                                />
                              ) : (
                                <Checkbox
                                  checked={
                                    o === "Ya"
                                      ? p.is_personal === true
                                      : o === "Tidak"
                                      ? p.is_personal === false
                                      : null
                                  }
                                  onClick={(e) =>
                                    handleCheck(
                                      o === "Ya" ? true : false,
                                      "is_personal",
                                      p
                                    )
                                  }
                                />
                              )
                            }
                            label={o}
                          />
                        ))}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                {p.type_form === "select" && (
                  <Grid item xs={4}>
                    {loadingCheck === p.illness_id ? (
                      <CircularProgress />
                    ) : (
                      <CustomAutoComplete
                        name="illness_id"
                        optionsArr={p.ref_select}
                        keyLabel={"name"}
                        handleSelect={(e) =>
                          handleSelectAuto(e, p.illness_id, p)
                        }
                        disable
                        value={
                          p.ref_select.filter((s) => s.id === p.value_select)[0]
                        }
                      />
                    )}
                  </Grid>
                )}
                {p.is_desc && (
                  <Grid item xs={6} sx={{ m: "auto 0" }}>
                    <CustomInput
                      label=""
                      multiline
                      rows={2}
                      style={{ marginBottom: "0px" }}
                      onBlur={(e) =>
                        handleBlur(e.target.value, "personal_desc", p)
                      }
                      defaultValue={p.personal_desc}
                    />
                  </Grid>
                )}
              </Grid>
            ))}

            <Typography variant="h5" sx={{ mt: "1rem" }}>
              Faktor Kesehatan Lainnya
            </Typography>
            {dataFactor.map((p) => (
              <Grid container sx={{ mb: "1rem" }}>
                <Grid
                  item
                  xs={p.type_form !== "select" ? 6 : 4}
                  sx={{ m: "auto 0" }}
                  spacing={2}
                >
                  <Grid container>
                    <Grid
                      item
                      sm={12}
                      md={p.type_form !== "select" ? 7 : 12}
                      sx={{ m: "auto 0" }}
                    >
                      {p.type_form !== "select" ? (
                        <Typography
                          sx={{
                            color:
                              p.is_personal === true || p.is_personal === false
                                ? "inherit"
                                : "#ff0000",
                          }}
                        >
                          {p.illness_name}
                        </Typography>
                      ) : (
                        <Typography>{p.illness_name}</Typography>
                      )}
                    </Grid>
                    {p.type_form !== "select" && (
                      <Grid item sm={12} md={5}>
                        <Stack direction="row" spacing={2}>
                          {["Ya", "Tidak"].map((o) => (
                            <FormControlLabel
                              control={
                                loadingCheck.illness_id === p.illness_id ? (
                                  <CircularProgress
                                    size={24}
                                    sx={{ mr: "1rem" }}
                                  />
                                ) : (
                                  <Checkbox
                                    checked={
                                      o === "Ya"
                                        ? p.is_personal === true
                                        : o === "Tidak"
                                        ? p.is_personal === false
                                        : null
                                    }
                                    onClick={(e) =>
                                      handleCheck(
                                        o === "Ya" ? true : false,
                                        "is_personal",
                                        p
                                      )
                                    }
                                  />
                                )
                              }
                              label={o}
                            />
                          ))}
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {p.is_desc && p.type_form !== "select" && (
                  <Grid item xs={4} sx={{ m: "auto 0" }}>
                    <CustomInput
                      label=""
                      multiline
                      rows={2}
                      style={{ marginBottom: "0px" }}
                      onBlur={(e) =>
                        handleBlur(e.target.value, "personal_desc", p)
                      }
                      defaultValue={p.personal_desc}
                    />
                  </Grid>
                )}

                {p.type_form === "select" && (
                  <>
                    <Grid item xs={4}>
                      {loadingCheck === p.illness_id ? (
                        <CircularProgress />
                      ) : (
                        <CustomAutoComplete
                          name="illness_id"
                          optionsArr={p.ref_select}
                          keyLabel={"name"}
                          handleSelect={(e) =>
                            handleSelectAuto(e, p.illness_id, p)
                          }
                          disable
                          value={
                            p.ref_select.filter(
                              (s) => s.id === p.value_select
                            )[0]
                          }
                        />
                      )}
                    </Grid>
                    {p.is_desc && (
                      <Grid item xs={4}>
                        <CustomInput
                          label=""
                          multiline
                          rows={2}
                          style={{ marginBottom: "0px" }}
                          onBlur={(e) =>
                            handleBlur(e.target.value, "personal_desc", p)
                          }
                          defaultValue={p.personal_desc}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            ))}
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%", my: "1.5rem" }}
          >
            <Button
              variant="outlined"
              sx={{
                border: "none",
                opacity: validates[val - 1] ? 1 : 0,
              }}
              onClick={(e) => handleChange(e, val - 1)}
              disabled={!validates[val - 1]}
              startIcon={<KeyboardArrowLeft />}
            >
              Pernyataan Pribadi
            </Button>
            <Button
              variant="outlined"
              sx={{
                border: "none",
                opacity: validates[val + 1] ? 1 : 0,
              }}
              onClick={(e) => handleMoveNext(e)}
              disabled={!validates[val + 1]}
              endIcon={<KeyboardArrowRight />}
            >
              Upload Dokumen
            </Button>
          </Stack>
        </>
      )}
    </Container>
  );
};

export default Illness;
