import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
  styled,
  CircularProgress,
  DialogActions,
  Stack,
} from "@mui/material";
import SignA from "src/pages/user/Sign/SignA";
import SignB from "src/pages/user/Sign/SignB";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
    // maxWidth: "350px",
  },
}));

const ModalSignContractRequired = ({ open, onClose, dataDetail }) => {
  useTheme();
  const [loading, setLoading] = useState("");
  const [isFormA, setIsFormA] = useState(true);

  return (
    <CustomDialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <b>Silahkan Isi Data Berikut</b>
      </DialogTitle>
      <DialogContent dividers>{isFormA ? <SignA /> : <SignB />}</DialogContent>

      <DialogActions>
        <Button>Batal</Button>
        {isFormA && (
          <Button color="error" variant="outlined">
            Mengundurkan Diri
          </Button>
        )}
        {loading === "buttonApply" ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            // onClick={handleApply}
            // disabled={!form.city}
          >
            Simpan dan {isFormA ? " Lanjutkan Proses" : "Kirim"}
          </Button>
        )}
      </DialogActions>
    </CustomDialog>
  );
};

export default ModalSignContractRequired;
