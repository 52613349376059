import React, { useState, useEffect } from "react";
import { Grid, Stack, useMediaQuery } from "@mui/material";
import UserColumn from "./UserColumn";
import { loadImage } from "src/functions/utils";

const UserRow = ({ data }) => {
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const isScreen1536 = useMediaQuery("(min-width:1536px)");
  const isScreen1440 = useMediaQuery("(min-width:1440px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const [src, setSrc] = useState("");
  const [bgImg, setBgImg] = useState("");
  const [poster, setPoster] = useState("");
  const [bg, setBg] = useState("");

  const renderColumn = (column) => {
    return <UserColumn key={column.id} data={column} />;
  };

  const maxWidthStyle = (maxWidth) => {
    if (isScreen2000) return "2000px";
    else if (isScreen900 && !isScreen2000 && maxWidth) return maxWidth;
    else if (isScreen1536) return "1440px";
    else if (isScreen1440) return "1200px";
    else if (isScreen1200) return "1024px";
    else if (!isScreen1200) return "900px";
    // if (isScreen1536) return "1200px";
    // else if (isScreen1440) return "1200px";
    // else if (isScreen1200) return "1024px";
    // else if (!isScreen1200) return "900px";
  };

  useEffect(async () => {
    const resNewGet = await loadImage(data.src, "video");
    const resBgImg = await loadImage(data.sectProps?.backgroundImage);
    const resPoster = await loadImage(data.poster);
    const resBg = await loadImage(data.props?.backgroundImage);
    setSrc(resNewGet);
    setBgImg(resBgImg);
    setPoster(resPoster);
    setBg(resBg);

    if (document.querySelector("#video-bg")) {
      document.querySelector("#video-bg").src = resNewGet;
    }
  }, []);

  return (
    <Stack
      component="section"
      className={data.sectCn && `sect ${data.sectCn}`}
      sx={{
        background: bgImg && `no-repeat center url(${bgImg})`,
        backgroundSize: bgImg && "cover",
        display:
          (data.sectProps?.alignItems || data.sectProps?.justifyContent) &&
          "flex", //CEK
        ...data.sectProps,

        aspectRatio:
          isScreen600 && data.sectCn === "root-stack"
            ? data.sectProps?.aspectRatio
            : !isScreen600 && data.sectCn === "root-stack"
            ? "1/1"
            : "",
      }}
    >
      {data.src && (
        <video
          id="video-bg"
          style={{
            aspectRatio: isScreen600 ? data.sectProps?.aspectRatio : "1/1",
          }}
          poster={poster}
          preload
          autoPlay
          autoBuffer="true"
          loop
          playsInline
          muted
        >
          {/* <source src={src} type="video/mp4"></source>
          <p>Your browser doesn't support HTML video.</p> */}
        </video>
      )}
      <Grid
        container
        id={data.id}
        className={data.rowCn && `row ${data.rowCn}`}
        sx={{
          background: bg && `no-repeat center url(${bg})`,
          backgroundSize: bg && "cover",
          display:
            (data.props?.alignItems || data.props?.justifyContent) && "flex", //cek
          position: "unset",
          ...data.props,

          maxWidth: maxWidthStyle(data.props?.maxWidth),
          m: "auto",
        }}
      >
        {data.children.map((column) => {
          return (
            <React.Fragment key={column.id}>
              {renderColumn(column)}
            </React.Fragment>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default UserRow;
