import React from "react";
import { Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { initialElement } from "../library";
import { useDrag } from "react-dnd";
import localforage from "localforage";
import { domain } from "src/components/Layout/dummy";
import { connect } from "react-redux";
import { setThemePreview, updateInfoInstitution } from "src/redux/actions";
import "../../../../App.css";
import {
  ArrowBack,
  Photo,
  SmartButton,
  Videocam,
  Wysiwyg,
  YouTube,
} from "@mui/icons-material";

const containerStyle = {
  width: "100%",
  height: "100%",
  position: "relative",
  backgroundColor: "#fff",
  overflowX: "auto",
  transition: "0.5s",
  boxShadow:
    "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
  display: "flex",
};

const iconCloseStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  mr: 2,
  my: 2,
  p: 1,
  pr: 1.5,
  width: "40px",
  height: "40px",
  "&:hover": {
    boxShadow: `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
    backgroundColor: "#f0f8ff",
    cursor: "pointer",
  },
};
const listFonts = [
  "Poppins",
  "Montserrat",
  "Lato",
  "Open Sans",
  "Roboto",
  "Oswald",
  "Playfair Display",
  "Raleway",
  "Merriweather",
  "Arial",
  "Noto Sans",
  "Abel",
  "Alegreya",
  "Dosis",
  "Arvo",
];

const FixedDrawer = ({
  stateDrawer,
  setStateDrawer,
  setRowObjEdit,
  themePreview,
}) => {
  const handleTheme = async (font) => {
    const token = await localforage.getItem("token");
    const copyTheme = { ...themePreview };
    copyTheme.font = font;
    // console.log(copyTheme)
    // copyTheme.websiteFor = "recruitment";
    // copyTheme.colorPrimary = "indigo";
    // copyTheme.colorSecondary = "yellow";
    setThemePreview(copyTheme);

    const obj = { token, domain };
    const jsonTheme = JSON.stringify(copyTheme);
    await updateInfoInstitution("theme", {
      ...obj,
      theme: jsonTheme,
    });
  };

  return (
    <>
      {stateDrawer === "fixCom" && (
        <Grid
          item
          lg={stateDrawer.indexOf("fixCom") !== -1 ? 2.5 : null}
          sx={containerStyle}
          className={
            stateDrawer.indexOf("fixCom") !== -1 ? "selected" : "dismiss"
          }
        >
          <IconButton
            sx={iconCloseStyle}
            onClick={() => {
              setRowObjEdit({});
              setStateDrawer("");
            }}
          >
            {/* <i class="fi fi-br-arrow-small-left"></i> */}
            <ArrowBack
              fontSize="small"
              sx={{ width: "1rem", height: "1rem" }}
            />
          </IconButton>
          <Grid
            item
            container
            sx={{ position: "absolute", top: "40px", left: 0 }}
          >
            <Stack direction="column" sx={{ width: "100%", p: 2 }}>
              <Typography
                variant="subtitle1"
                color="text.primary"
                sx={{ fontWeight: "bold" }}
              >
                Element
              </Typography>
            </Stack>

            {initialElement
              .filter((elm) => elm.variant.match("element"))
              .map((el) => (
                <Item key={el.id} elm={el} />
              ))}

            <Stack direction="column" sx={{ width: "100%", p: 2, py: 2.5 }}>
              <Typography
                variant="subtitle1"
                color="text.primary"
                sx={{ fontWeight: "bold" }}
              >
                Section
              </Typography>
            </Stack>

            {initialElement
              .filter((elm) => elm.variant.match("section"))
              .map((elm) => (
                <Item key={elm.id} elm={elm} />
              ))}
          </Grid>
        </Grid>
      )}
      {stateDrawer === "fixTheme" && (
        <Grid
          item
          lg={stateDrawer.indexOf("fixTheme") !== -1 ? 2.5 : null}
          sx={containerStyle}
          className={
            stateDrawer.indexOf("fixTheme") !== -1 ? "selected" : "dismiss"
          }
        >
          <IconButton
            sx={iconCloseStyle}
            onClick={() => {
              setRowObjEdit({});
              setStateDrawer("");
            }}
          >
            {/* <i class="fi fi-br-arrow-small-left"></i> */}
            <ArrowBack
              fontSize="small"
              sx={{ width: "1rem", height: "1rem" }}
            />
          </IconButton>
          <Grid
            item
            container
            sx={{ position: "absolute", top: "40px", left: 0 }}
          >
            <Stack direction="column" sx={{ width: "100%", p: 2, py: 2.5 }}>
              <Typography
                variant="subtitle1"
                color="text.primary"
                sx={{ fontWeight: "bold" }}
              >
                Font
              </Typography>
            </Stack>

            {listFonts.map((font, i) => (
              <Paper
                key={i}
                sx={{
                  borderRadius: "0.75rem",
                  p: 1.5,
                  width: "100%",
                  m: 0.5,
                  mx: 2,
                  backgroundColor: themePreview?.font === font && "#f0f8ff",
                  "&:hover": {
                    backgroundColor: "#f0f8ff",
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleTheme(font)}
              >
                <Typography
                  variant="body1"
                  sx={{ fontFamily: font, textAlign: "center" }}
                >
                  {font}
                </Typography>
              </Paper>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  themePreview: state.preview.themePreview,
});

export default connect(mapStateToProps)(FixedDrawer);

const Item = ({ elm }) => {
  const [{ opacity }, drag] = useDrag({
    item: elm,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  const filterIcon = (variant) => {
    switch (variant) {
      case "element0":
        // return <i class="fi fi-sr-text"></i>;
        return <Wysiwyg />;
      case "element1":
        // return <i class="fi fi-brands-youtube"></i>;
        return <YouTube />;
      case "element2":
        // return <i class="fi fi-sr-video-camera-alt"></i>;
        return <Videocam />;
      case "element3":
        // return <i class="fi fi-br-picture"></i>;
        return <Photo />;
      case "element4":
        // return <i class="fi fi-sr-stop"></i>;
        return <SmartButton />;
      default:
        break;
    }
  };

  return (
    <Grid
      item
      xl={4}
      md={4}
      ref={drag}
      sx={{
        opacity,
        "&:hover": {
          boxShadow: `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
          backgroundColor: "#f0f8ff",
          borderRadius: "0.75rem",
          cursor: "pointer",
        },
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          m: "auto",
          px: 1,
          py: 2,
        }}
      >
        <IconButton
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
              cursor: "default",
            },
          }}
        >
          {filterIcon(elm.variant)}
        </IconButton>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {elm.variantText}
        </Typography>
      </Stack>
    </Grid>
  );
};
