import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import UserRow from "src/components/Layout/UserRow";
import { domain } from "src/components/Layout/dummy";
import { customPageAPI } from "src/redux/actions";
import "src/App.css";
import { version } from "src/configs/globalVariable";

const Index = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState("container");
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    checkUrl();
    fetchData();
  }, [params]);

  const checkUrl = () => {
    if (!params.page || !params.idGroup) {
      navigate(`/${version}/user/`);
      return;
    }
  };

  const fetchData = async () => {
    let resData;
    if (params.id) {
      resData = await customPageAPI("get", "content", {
        domain,
        landing_content_id: params.id,
      });
    } else if (params.idGroup) {
      resData = await customPageAPI("get", "group", {
        domain,
        landing_content_group_id: params.idGroup,
      });
    }
    setData(resData.data[0].content);
    setLoading(false);
  };

  const renderRow = (row) => {
    return <UserRow key={row.id} data={row} />;
  };

  if (loading === "container")
    return (
      <Stack>
        <CircularProgress sx={{ m: "auto" }} />
      </Stack>
    );

  return (
    <Grid
      component="main"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F6F8FD",
      }}
    >
      {data
        .filter((v) => v.visibility)
        .map((row) => {
          return <React.Fragment key={row.id}>{renderRow(row)}</React.Fragment>;
        })}
    </Grid>
  );
};

export default Index;
