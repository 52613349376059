import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
  styled,
  CircularProgress,
  DialogActions,
  Stack,
} from "@mui/material";
import {
  setSnackbarOpen,
  getJob,
  userApplyJob,
  getEducation,
  updateInvitedGenerator,
} from "src/redux/actions";
import { useNavigate } from "react-router";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import { version } from "src/configs/globalVariable";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
    // maxWidth: "350px",
  },
}));

const CustomCircularProgress = styled(CircularProgress)(
  ({ theme, loading }) => ({
    "&.MuiCircularProgress-root": {
      height: "20px !important",
      width: "20px !important",
      display: "flex",
      color: loading === "google" ? `${theme.palette.primary.main}` : "#ffff",
      marginRight: loading === "google" ? "0.5rem !important" : null,
    },
  })
);

const ModalConfirmApplyJob = ({
  open,
  onClose,
  defaultBio,
  jobPostingId,
  positionGroupName,
  setLoadingButton,
  invited_generator_id,
}) => {
  const navigate = useNavigate();
  useTheme();
  const [loading, setLoading] = useState("content");
  const [jobDetail, setJobDetail] = useState({});
  const [myEdu, setMyEdu] = useState([]);
  const [selectedEdu, setSelectedEdu] = useState({});

  useEffect(() => {
    fetchJobDetail();
  }, [open]);

  const fetchJobDetail = async () => {
    const resRef = await Promise.all([
      getJob(jobPostingId),
      getEducation({ type: "apply-job" }),
    ]);

    setJobDetail(resRef[0]?.data[0]);
    resRef[1].data.map((d) => {
      d.label =
        (d.study_program?.study_program_name || d?.study_program_text) +
        " - " +
        d.educational_stage?.educational_name;
    });
    if (setLoadingButton) {
      setLoadingButton("");
    }
    const filterdEdu = [];
    resRef[1].data?.map((r) => {
      resRef[0]?.data[0]?.educational_codes?.map((e) => {
        if (r.educational_code === e.educational_code) {
          filterdEdu.push(r);
        }
      });
    });
    setMyEdu(filterdEdu);
    setLoading("");
  };

  const handleApply = async () => {
    setLoading("buttonApply");
    const copyForm = { ...defaultBio };
    copyForm.jobPostingId = jobPostingId;
    copyForm.positionGroupName = positionGroupName;
    copyForm.bio_edu_id = selectedEdu.bio_edu_id;

    const resApply = await userApplyJob(copyForm);
    if (resApply.code !== 200) {
      setSnackbarOpen("error", resApply.message);
      setLoading("");
      return;
    }
    if (invited_generator_id) {
      await updateInvitedGenerator({ invited_generator_id });
      localStorage.removeItem("invited");
      navigate(`/${version}/user/dashboard/my-career`);
    }
    setSnackbarOpen("success", "Anda berhasil mendaftarkan diri");
    setLoading("");
    onClose();
    setTimeout(() => {
      navigate(`/${version}/user/dashboard/my-career`);
    }, 500);
  };

  const handleToProfile = () => navigate(`/${version}/user/profile`);
  if (loading === "content") return <CustomCircularProgress />;

  return (
    <CustomDialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <b>Konfirmasi</b>
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Anda akan melamar pada posisi <b>{jobDetail.position_group_name}</b>
        </Typography>

        <Typography sx={{ fontWeight: 500, fontSize: "14px" }} gutterBottom>
          Pilihlah pendidikan yang sesuai dengan persyaratan untuk seleksi
          administrasi.
        </Typography>
        <CustomAutoComplete
          name="bio_edu_id"
          optionsArr={myEdu}
          keyLabel={"label"}
          handleSelect={(val) => setSelectedEdu(val)}
          register="true"
        />
        {/* <Typography sx={{ mt: "0.5rem", fontSize: "14px" }}>
          Silahkan buka halaman{" "}
          <i
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={handleToProfile}
          >
            Profile
          </i>{" "}
          untuk mengisi/mengubah data pendidikan
        </Typography> */}
        <Typography sx={{ mt: "0.5rem", fontSize: "14px" }}>
          Pilih riwayat pendidikan yang akan anda gunakan untuk melamar
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Batal</Button>
        {loading === "buttonApply" ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            onClick={handleApply}
            disabled={!selectedEdu.bio_edu_id}
          >
            Lamar
          </Button>
        )}
      </DialogActions>
    </CustomDialog>
  );
};

export default ModalConfirmApplyJob;
