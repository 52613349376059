import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Avatar,
  Button,
  Divider,
  CardActionArea,
  Card,
  Grid,
  Stack,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  refFamilyStatus,
  refMaritalStatus,
  getFamily,
  createFamily,
  updateFamily,
  deleteFamily,
  setSnackbarOpen,
} from "src/redux/actions";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import CustomDatePicker from "src/components/CustomDatePicker";
import { parsedDate } from "src/functions/parsedDate";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const dummyData = [
  {
    family_status: {
      family_status_name: "Ayah",
    },
    marital_status: {
      marital_status_name: "Nikah",
    },
    gender: {
      gender_name: "Laki-laki",
    },
    fullname: "Test 123",
    birth_date: "1971-02-23",
    occupation: "Developer",
    bio_family_id: "123123123",
  },
];

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const Family = ({ handleChange }) => {
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState("");
  const [families, setFamilies] = useState([]);
  const [familyStatus, setFamilyStatus] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [form, setForm] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [validate, setValidate] = useState(false);
  const [validateTxt] = useState("* required");
  const [validateTxtMin, setValidateTxtMin] = useState("");
  const validates = JSON.parse(localStorage.getItem("validates"));
  const val = parseInt(localStorage.getItem("value"));

  useEffect(() => {
    fetchRef();
    getFam();
  }, []);

  useEffect(() => {
    if (families.length < 2)
      setValidateTxtMin("* required min 2 data of family members");
    else {
      setValidateTxtMin("");
      validates[val + 1] = true;
      localStorage.setItem("validates", JSON.stringify(validates));
    }
  }, [families]);

  const fetchRef = async () => {
    const resRef = await Promise.all([refFamilyStatus(), refMaritalStatus()]);
    setFamilyStatus(resRef[0].data);
    setMaritalStatus(resRef[1].data);
  };

  const getFam = async () => {
    setLoading(true);
    const resFam = await getFamily();
    setFamilies(resFam.data);
    setLoading(false);
  };

  const handleSelectAuto = async (val, key) => {
    setForm({ ...form, [key]: val[key] });
  };

  function tambahkanSatuHari(tanggal) {
    let tanggalBaru = new Date(tanggal);
    tanggalBaru.setHours(tanggalBaru.getHours() + 12);
    return tanggalBaru;
  }

  const handleDateChange = (val, name) => {
    setForm({ ...form, [name]: val });
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleEdit = (d) => {
    setForm(d);
    setOpenForm("edit");
  };

  const handleDelete = (d) => {
    setForm(d);
    setAlertOpen(true);
  };

  const handleSubmitDelete = async () => {
    await deleteFamily(form);
    setForm({});
    setSnackbarOpen("success", "Data berhasil dihapus");
    setAlertOpen(false);
    getFam();
  };

  const handleSave = async () => {
    if (
      !form.family_status_id ||
      !form.fullname ||
      !form.gender_code ||
      !form.birth_date ||
      !form.education_major ||
      !form.occupation
    ) {
      setValidate(true);
      return;
    }
    setValidate(false);
    form.birth_date = tambahkanSatuHari(form.birth_date);
    let res;
    if (openForm === "new") {
      res = await createFamily(form);
    } else {
      res = await updateFamily(form);
    }
    if (res.code === 200) {
      setSnackbarOpen("success", "Data disimpan");
    } else {
      setSnackbarOpen(
        "error",
        "Data belum berhasil disimpan, mohon dicoba kembali."
      );
    }
    setOpenForm("");
    setForm({});
    getFam();
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Data Keluarga</b>
        <span
          style={{ fontSize: "16px" }}
        >{`(Tuliskan data orang tua, kakak/adik sesuai keurutan kelahiran. Jika anda sudah menikah tuliskan juga data pasangan & anak. Mohon disebutkan data keluarga Anda. Beri tanda (alm.) di belakang nama apabila sudah meninggal.)`}</span>
      </Typography>
      {!openForm && (
        <Button
          variant="contained"
          sx={{ display: "flex", m: "auto 0 1rem auto" }}
          onClick={() => setOpenForm("new")}
        >
          Tambah
        </Button>
      )}
      {openForm && (
        <>
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Status Keluarga
          </Typography>
          <CustomAutoComplete
            name="family_status_id"
            optionsArr={familyStatus}
            keyLabel={"family_status_name"}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            value={form.family_status}
            error={validate && !form.family_status_id}
            helpertext={validate && !form.family_status_id && validateTxt}
          />
          <CustomInput
            label="Nama Lengkap"
            name="fullname"
            onChange={handleChangeInput}
            value={form.fullname}
            error={validate && !form.fullname}
            helpertext={validate && !form.fullname && validateTxt}
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Jenis Kelamin
          </Typography>
          <CustomAutoComplete
            name="gender_code"
            optionsArr={[
              { gender_code: 1, gender_name: "Laki-laki" },
              { gender_code: 2, gender_name: "Perempuan" },
            ]}
            keyLabel={"gender_name"}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            value={form.gender}
            error={validate && !form.gender_code}
            helpertext={validate && !form.gender_code && validateTxt}
          />
          <CustomDatePicker
            label="Tanggal Lahir"
            name="birth_date"
            onChange={handleDateChange}
            value={form.birth_date ? form.birth_date : null}
            error={validate && !form.birth_date}
            helpertext={validate && !form.birth_date && validateTxt}
          />
          <CustomInput
            label="Jenjang Pendidikan Terakhir & Jurusan"
            name="education_major"
            onChange={handleChangeInput}
            value={form.education_major}
            error={validate && !form.education_major}
            helpertext={validate && !form.education_major && validateTxt}
          />
          <CustomInput
            label="Pekerjaan"
            name="occupation"
            onChange={handleChangeInput}
            value={form.occupation}
            error={validate && !form.occupation}
            helpertext={validate && !form.occupation && validateTxt}
          />
          {/* <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Status Pernikahan
          </Typography>
          <CustomAutoComplete
            name="marital_status_id"
            optionsArr={maritalStatus}
            keyLabel={"marital_status_name"}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            value={form.marital_status}
          /> */}
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"end"}
            sx={{ my: "1rem", mt: "2rem" }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setOpenForm("");
                setForm({});
              }}
            >
              Batal
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Simpan
            </Button>
          </Stack>

          <Divider sx={{ mt: 2, mb: 2 }} />
        </>
      )}
      <Grid container sx={{ pr: 2, pl: 2 }}>
        <Grid item sm={2} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
            Nama
          </Typography>
        </Grid>
        <Grid item sm={2} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Jenis Kelamin</Typography>
        </Grid>
        <Grid item sm={2} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Tanggal Lahir</Typography>
        </Grid>
        {/* <Grid item sm={2} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Pekerjaan</Typography>
        </Grid> */}
        <Grid item sm={2} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Status Keluarga</Typography>
        </Grid>
        {/* <Grid item sm={3} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Status Pernikahan</Typography>
        </Grid> */}
        <Grid item sm={1} sx={{ m: "auto" }}></Grid>
      </Grid>
      {families.map((family) => (
        <Card
          id={family.bio_family_id}
          sx={{
            backgroundColor: "#FFF",
            p: 2,
            borderRadius: "16px",
            mt: "0.5rem",
          }}
        >
          <Grid container>
            <Grid item sm={2} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                {family.fullname}
              </Typography>
            </Grid>
            <Grid item sm={2} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {family.gender?.gender_name}
              </Typography>
            </Grid>
            <Grid item sm={2} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {parsedDate(family.birth_date)}
              </Typography>
            </Grid>
            {/* <Grid item sm={2} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {family.occupation}
              </Typography>
            </Grid> */}
            <Grid item sm={2} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {family.family_status?.family_status_name}
              </Typography>
            </Grid>
            {/* <Grid item sm={3} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {family.marital_status?.marital_status_name}
              </Typography>
            </Grid> */}
            <Grid item sm={1} sx={{ m: "auto" }}>
              <Stack direction="row" justifyContent={"end"} spacing={1}>
                <IconButton onClick={() => handleEdit(family)}>
                  <EditRoundedIcon />{" "}
                </IconButton>
                <IconButton onClick={() => handleDelete(family)}>
                  <DeleteRoundedIcon />{" "}
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      ))}
      {validateTxtMin && (
        <Typography
          variant="caption"
          sx={{ color: "#ff0000", mb: 4, mt: 4, textAlign: "center" }}
        >
          {validateTxtMin}
        </Typography>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", mt: "3rem" }}
      >
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val - 1] ? 1 : 0,
          }}
          onClick={(e) => handleChange(e, val - 1)}
          disabled={!validates[val - 1]}
          startIcon={<KeyboardArrowLeft />}
        >
          Data Diri
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val + 1] ? 1 : 0,
          }}
          onClick={(e) => {
            if (validateTxtMin) return;
            setValidateTxtMin(false);
            handleChange(e, val + 1);
          }}
          disabled={!validates[val + 1]}
          endIcon={<KeyboardArrowRight />}
        >
          Data Pendidikan
        </Button>
      </Stack>

      <CustomDialog open={alertOpen} fullWidth maxWidth="xs">
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent>Data {form.fullname} akan dihapus</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setAlertOpen(false);
              setForm({});
            }}
          >
            Batal
          </Button>
          <Button variant="contained" onClick={handleSubmitDelete}>
            Ya, Hapus
          </Button>
        </DialogActions>
      </CustomDialog>
    </Container>
  );
};

export default Family;
