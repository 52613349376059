import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { history } from "../../history";
import auth from "./auth-reducer";
import app from "./app-reducer";
import nav from "./nav-reducer";
import preview from "./preview-reducer";

const reducers = combineReducers({
  router: connectRouter(history),
  app,
  auth,
  nav,
  preview,
});

export default reducers;
