import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  useTheme,
  styled,
  Grid,
  Stack,
} from "@mui/material";
import {
  HistoryEduRounded,
  StyleRounded,
  BadgeRounded,
} from "@mui/icons-material";
import {
  setOpenLogin,
  loginInst,
  setMiddlewareApps,
  loginJp,
  setAuthenticated,
} from "src/redux/actions";
import { useNavigate } from "react-router";
import localforage from "localforage";
import { version } from "src/configs/globalVariable";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
    maxWidth: "350px",
  },
}));

const MiddlewareApps = ({ onClose, mdlApps }) => {
  useTheme();
  const navigate = useNavigate();

  // useEffect(() => {}, [mdlApps.open]);

  const handleSelect = async (d) => {
    if (d === "landing") {
      await localforage.setItem("userRole", "5");
      setAuthenticated(mdlApps?.resLogin?.data?.token);
      // setMiddlewareApps(false, [], { email: "", password: "" }, null);
      // setOpenLogin(false);
      window.open(window.location.origin + `/${version}/admin/`);
      // navigate(`/${version}/admin/`);
    }

    if (d === "cms") {
      // setMiddlewareApps(false, [], { email: "", password: "" }, null);
      // setOpenLogin(false);
      await loginInst(mdlApps?.resLogin?.data?.token);
    }

    if (d === "jp") {
      const resJp = await loginJp(mdlApps?.form);
      if (resJp.code !== 200) {
        return;
      }
      // setMiddlewareApps(false, [], { email: "", password: "" }, null);
      // setOpenLogin(false);
      window.open(window.location.origin + "/jp/");
      // navigate("/jp/");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  return (
    <CustomDialog
      open={mdlApps?.open || false}
      onClose={onClose}
      maxWidth={"xs"}
      fullWidth
    >
      <DialogContent>
        <Typography variant="h4" sx={{ mb: 4 }}>
          Select Apps
        </Typography>
        <Grid container spacing={2}>
          {mdlApps?.data?.indexOf(5) !== -1 && (
            <Grid item sm={6}>
              <Button
                variant="outlined"
                fullWidth
                sx={{ height: "100px", p: "0" }}
                onClick={() => handleSelect("landing")}
              >
                <Stack direction={"column"} sx={{ width: "100%" }}>
                  <StyleRounded
                    sx={{ m: "auto", width: "50px", height: "50px" }}
                  />
                  <Typography sx={{ mt: "0.5rem", fontSize: "14px" }}>
                    Admin Landing
                  </Typography>
                </Stack>
              </Button>
            </Grid>
          )}
          {(mdlApps?.data?.indexOf(1) !== -1 ||
            mdlApps?.data?.indexOf(3) !== -1) && (
            <Grid item sm={6}>
              <Button
                variant="outlined"
                fullWidth
                sx={{ height: "100px", p: "0" }}
                onClick={() => handleSelect("cms")}
              >
                <Stack direction={"column"} sx={{ width: "100%" }}>
                  <HistoryEduRounded
                    sx={{ m: "auto", width: "50px", height: "50px" }}
                  />
                  <Typography sx={{ mt: "0.5rem", fontSize: "14px" }}>
                    CMS
                  </Typography>
                </Stack>
              </Button>
            </Grid>
          )}
          {mdlApps?.data?.indexOf(12) !== -1 && (
            <Grid item sm={6}>
              <Button
                variant="outlined"
                fullWidth
                sx={{ height: "100px", p: "0" }}
                onClick={() => handleSelect("jp")}
              >
                <Stack direction={"column"} sx={{ width: "100%" }}>
                  <BadgeRounded
                    sx={{ m: "auto", width: "50px", height: "50px" }}
                  />
                  <Typography sx={{ mt: "0.5rem", fontSize: "14px" }}>
                    Job Posting
                  </Typography>
                </Stack>
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </CustomDialog>
  );
};

export default MiddlewareApps;
