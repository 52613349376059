import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  useMediaQuery,
  IconButton,
  Button,
  Autocomplete,
  useTheme,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import {
  getRefZip,
  getRefEdu,
  registerForm,
  setAuthenticated,
  getRefRegion,
  getRef,
  getRefUniSP,
  createEducation,
} from "src/redux/actions";
import localforage from "localforage";
import { useNavigate } from "react-router";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { inputNumberOnly } from "src/functions/inputNumberOnly";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import CustomInput from "./CustomInput";
import { version } from "src/configs/globalVariable";

const styleGrid = {
  p: 2,
  backgroundColor: "#ffff",
  m: "auto",
  borderRadius: "0.75rem",
  boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
};

const Register = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({});
  const [tempBD, setTempBD] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [openSelect, setOpenSelect] = useState(false);
  const [refZip, setRefZip] = useState([]);
  const [refEdu, setRefEdu] = useState([]);
  const [validate, setValidate] = useState(false);
  const [cities, setCities] = useState([]);
  const [university, setUniversity] = useState([]);
  const [loadingEdu, setLoadingEdu] = useState(false);
  const [uniSp, setUniSp] = useState([]);
  const [loadingReFetch, setLoadingReFetch] = useState(false);
  const [loading, setLoading] = useState("container");
  // const isScreen2000 = useMediaQuery("(min-width:2000px)");
  // const isScreen1400 = useMediaQuery("(min-width:1400px)");

  useEffect(() => {
    fetchZip();
    fetchEdu();
    fetchCity();
    fetchRef();
  }, []);

  const fetchRef = async () => {
    const resEmail = await localforage.getItem("email");
    const copyForm = { ...form };
    copyForm["email"] = resEmail;
    setForm(copyForm);
    setLoading(false);
  };

  const fetchCity = async () => {
    const resCity = await getRefRegion({ province: "all" });
    setCities(resCity.data);
  };

  const fetchZip = async (e, code) => {
    setInputValue(code);
    if (code.length > 0) {
      setOpenSelect(true);
      const resZip = await getRefZip(code || "all");
      setRefZip(resZip.data);
    } else {
      setOpenSelect(false);
    }
  };

  const handleOpenSelect = () => {
    if (inputValue.length > 0) {
      setOpenSelect(true);
    }
  };

  const fetchEdu = async () => {
    const resRef = await Promise.all([
      getRefEdu(),
      getRef({ type: "refUniversity" }),
    ]);
    setRefEdu(resRef[0].data);
    setUniversity(resRef[1].data);
    const resEmail = await localforage.getItem("email");
    const resFullname = await localforage.getItem("fullname");
    const copyForm = { ...form };
    copyForm["email"] = resEmail;
    copyForm["fullname"] = resFullname;
    setForm(copyForm);
    setLoading(false);
  };

  const handleChange = (e) => {
    const copyForm = { ...form };
    copyForm[e.target.name] = e.target.value;
    setForm(copyForm);
  };

  const handleSelectGender = (d) => {
    const copyForm = { ...form };
    copyForm["gender_code"] = d.gender_code;
    setForm(copyForm);
  };

  const handleSelectZip = (d) => {
    const copyForm = { ...form };
    copyForm["id_pos"] = d.id_pos;
    copyForm["zipcode"] = d.kode_pos;
    copyForm["city"] = d.kota;
    copyForm["province"] = d.provinsi;
    setForm(copyForm);
  };

  const handleSelectEdu = async (d) => {
    const copyForm = { ...form };
    copyForm["educational_code"] = d.educational_code;
    setForm(copyForm);
    const res = await getRefUniSP({
      university_id: form.university_id,
      educational_code: d.educational_code,
    });
    setUniSp(res.data);
  };

  const handleDatePicker = (d) => {
    const copyForm = { ...form };
    setTempBD(d);

    const dd = d.getDate();
    const mm = d.getMonth() + 1;
    const yyyy = d.getFullYear();
    copyForm["birth_date"] = `${yyyy}-${mm}-${dd}`;
    setForm(copyForm);
  };

  const handleSubmit = async () => {
    setValidate(true);
    const con =
      !form.email ||
      !form.identity_number ||
      !form.fullname ||
      !form.nickname ||
      !form.password ||
      !form.birth_place ||
      !form.birth_date ||
      !form.gender_code ||
      !form.educational_code ||
      // !form.university ||
      !form.mobile_phone ||
      !form.zipcode ||
      !form.address;
    if (con) return;

    setLoading("buttonRegist");
    const resRegist = await registerForm({ data_personal: form });
    setLoading("");

    if (resRegist.code === 200) {
      setAuthenticated(resRegist.data.token);
      navigate(`/${version}/user/`);
    }
  };

  const handleSelectAutoBirthCity = (val, key) => {
    const copyForm = { ...form };
    copyForm.birth_place = val.city;
    setForm(copyForm);
  };

  const handleSelectAuto = async (val, key) => {
    setForm({ ...form, [key]: val[key] });
    if (key === "university_id") {
      const res = await getRefUniSP({
        university_id: val[key],
        educational_code: form.educational_code,
      });
      setUniSp(res.data);
    } else if (key === "university_study_program_id") {
      setLoadingEdu(true);
      setForm({
        ...form,
        [key]: val[key],
        educational_code: val.educational_code,
        educational_stage: refEdu.filter(
          (edu) => edu.educational_code === val.educational_code
        )[0],
      });
      setTimeout(() => {
        setLoadingEdu(false);
      }, 500);
    }
  };

  const handleReFetchUniSp = async (val) => {
    setLoadingReFetch(true);
    const res = await getRefUniSP({
      university_id: form.university_id,
      study_program_name: val,
      educational_code: form.educational_code,
    });
    setUniSp(res.data);
    setLoadingReFetch(false);
  };

  if (loading === "container")
    return (
      <Stack>
        <CircularProgress sx={{ m: "auto" }} />
      </Stack>
    );

  return (
    <Grid maxWidth={"lg"} sx={styleGrid}>
      <Stack sx={{ width: "100%", p: 2, mt: 2 }}>
        <Typography sx={{ fontSize: "24px" }}>
          <b>Registrasi</b>
        </Typography>
      </Stack>

      <Grid item container sx={{ mt: 2 }}>
        <Grid item sm={6} sx={{ p: 2 }}>
          <CustomInput label="Email" disabled value={form.email} />
          <CustomInput
            label="Nomor KTP"
            name="identity_number"
            onChange={handleChange}
            onKeyDown={inputNumberOnly}
            value={form.identity_number}
            error={validate && !form.identity_number}
            helperText={
              validate && !form.identity_number && "* Nomor KTP harap diisi"
            }
          />
          <CustomInput
            label="Nama Lengkap (Sesuai KTP)"
            name="fullname"
            onChange={handleChange}
            value={form.fullname}
            error={validate && !form.fullname}
            helperText={
              validate && !form.fullname && "* Nama lengkap harap diisi"
            }
          />
          <CustomInput
            label="Nama Panggilan"
            name="nickname"
            onChange={handleChange}
            value={form.nickname}
            error={validate && !form.nickname}
            helperText={
              validate && !form.nickname && "* Nama panggilan harap diisi"
            }
          />
          <CustomInput
            label="Kata Sandi"
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            value={form.password}
            error={validate && !form.password}
            helperText={
              validate && !form.password && "* Kata sandi harap diisi"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility color="primary" />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Kota Kelahiran
          </Typography>
          <CustomAutoComplete
            name="city"
            optionsArr={cities}
            keyLabel={"city"}
            handleSelect={handleSelectAutoBirthCity}
            register="true"
            value={form.birth_place ? { city: form.birth_place } : null}
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px", mt: "1rem" }}>
            Tanggal Kelahiran
          </Typography>
          <MobileDatePicker
            value={tempBD}
            DialogProps={{
              sx: {
                "& .MuiPaper-root": { backgroundColor: "#ffff !important" },
              },
            }}
            onChange={handleDatePicker}
            renderInput={(params) => (
              <CustomInput
                hiddenLabel
                errorValidate={validate && !form.birth_date ? true : false}
                error={validate && !form.birth_date}
                helperText={
                  validate &&
                  !form.birth_date &&
                  "* Tanggal kelahiran harap diisi"
                }
                {...params}
              />
            )}
          />
        </Grid>

        <Grid item sm={6} sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Jenis Kelamin
          </Typography>
          <CustomAutoComplete
            name="gender_code"
            optionsArr={[
              { gender_code: 1, gender_name: "Laki-laki" },
              { gender_code: 2, gender_name: "Perempuan" },
            ]}
            keyLabel={"gender_name"}
            handleSelect={handleSelectGender}
            validate={validate}
            value={form.gender_code}
            register="true"
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px", mt: "1rem" }}>
            Pendidikan Terakhir
          </Typography>
          <CustomAutoComplete
            name="educational_code"
            optionsArr={refEdu}
            keyLabel={"educational_name"}
            handleSelect={handleSelectEdu}
            validate={validate}
            value={form.educational_code}
            register="true"
          />
          {form.educational_code === "001" ||
          form.educational_code === "002" ||
          form.educational_code === "01" ||
          form.educational_code === "02" ||
          form.educational_code === "03" ||
          form.educational_code === "031" ||
          form.educational_code === "04" ||
          form.educational_code === "041" ||
          form.educational_code === "042" ? (
            <>
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", mt: "1rem" }}
              >
                Asal sekolah/universitas (saat ini)
              </Typography>
              <CustomAutoComplete
                name="university_id"
                optionsArr={university}
                keyLabel={"university_name"}
                handleSelect={handleSelectAuto}
                register="true"
                value={
                  university.filter(
                    (u) => u.university_id === form.university_id
                  )[0]
                }
              />
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", mt: "1rem" }}
              >
                Jurusan/Fakultas
              </Typography>
              <CustomAutoComplete
                name="university_study_program_id"
                optionsArr={loadingReFetch ? [] : uniSp}
                loading={loadingReFetch}
                keyLabel={"sp_stage_name"}
                handleSelect={handleSelectAuto}
                fetchData={handleReFetchUniSp}
                register="true"
                value={
                  uniSp.filter(
                    (u) =>
                      u.university_study_program_id ===
                      form.university_study_program_id
                  )[0]
                }
                sx={{ mb: "1rem" }}
              />
            </>
          ) : (
            <>
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", mt: "1rem" }}
              >
                Asal sekolah/universitas (saat ini)
              </Typography>
              <CustomInput
                hiddenLabel
                name="university"
                onChange={handleChange}
                value={form.university}
                error={validate && !form.university}
                helperText={
                  validate &&
                  !form.university &&
                  "* Asal sekolah/universitas harap diisi"
                }
              />
            </>
          )}
          <CustomInput
            label="Nomor Handphone"
            name="mobile_phone"
            onChange={handleChange}
            value={form.mobile_phone}
            error={validate && !form.mobile_phone}
            helperText={
              validate && !form.mobile_phone && "* Nomor handphone harap diisi"
            }
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Ketikkan Kode Pos
          </Typography>
          <Autocomplete
            open={openSelect}
            onOpen={handleOpenSelect}
            onClose={() => setOpenSelect(false)}
            id="zipcode"
            options={refZip}
            fullWidth
            size="small"
            getOptionLabel={(option) => option.pos_name}
            onInputChange={fetchZip}
            onChange={(e, val) => handleSelectZip(val)}
            renderInput={(params) => (
              <CustomInput
                hiddenLabel
                error={validate && !form.zipcode}
                helperText={
                  validate && !form.zipcode && "* Kode pos harap diisi"
                }
                {...params}
              />
            )}
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px", mt: "1rem" }}>
            Alamat
          </Typography>
          <CustomInput
            hiddenLabel
            name="address"
            onChange={handleChange}
            value={form.address}
            error={validate && !form.address}
            helperText={validate && !form.address && "* Alamat harap diisi"}
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <Stack
        direction="row"
        spacing={2}
        justifyContent={"end"}
        sx={{ my: "1rem", pr: 2, width: "100%" }}
      >
        <Button variant="outlined" oonClick={() => navigate(-1)}>
          Batal
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={loading === "buttonRegist"}
        >
          Kirim
        </Button>
      </Stack>
    </Grid>
  );
};

export default Register;
