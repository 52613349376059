import React, { useState, useEffect } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { loadImage } from "src/functions/utils";
import UserElement from "./UserElement";

const UserColumn = ({ data }) => {
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const [bgImg, setBgImg] = useState("");

  const renderElement = (element) => {
    return <UserElement key={element.id} data={element} />;
  };

  const flexDirectionStyle = () => {
    if (
      (data.props?.alignItems || data.props?.justifyContent) &&
      data.children.length === 1
    ) {
      return "column";
    } else if (
      (data.props?.alignItems || data.props?.justifyContent) &&
      data.children.length > 1
    ) {
      return "row";
    } else return null;
  };

  useEffect(async () => {
    const resBgImg = await loadImage(data.sectProps?.backgroundImage);
    setBgImg(resBgImg);
  }, []);

  return (
    <Grid
      item
      container
      md={
        data?.colCn?.indexOf("simpleScrollItem") > -1
          ? data.colWidth - 0.5
          : data.colWidth
      }
      xs={12}
      id={data.id}
      className={data.colCn && `col ${data.colCn}`}
      sx={{
        background: bgImg && `no-repeat center url(${bgImg})`,
        backgroundSize: bgImg && "cover",
        display:
          (data.props?.alignItems || data.props?.justifyContent) && "flex",
        flexDirection: flexDirectionStyle(),
        ...data.props,

        height:
          !isScreen900 && data.colCn === "simpleScroll"
            ? null
            : data.props?.height,
        // border: "5px solid aquamarine",
      }}
    >
      {data.children.map((element) => {
        return (
          <React.Fragment key={element.id}>
            {renderElement(element)}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
export default UserColumn;
