import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import UserRow from "src/components/Layout/UserRow";
import localforage from "localforage";
import { connect } from "react-redux";
import { insert } from "src/pages/admin/settingV3/helpers";
import "src/App.css";

const Index = ({ heightHeader }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const resTempData = await localforage.getItem("tempData");
    const resTheme = await localforage.getItem("theme");

    const num = heightHeader?.split("rem")[0];
    tempDataContent.sectProps.pt = num * 3 + "rem";
    tempDataContent.sectProps.pb = num * 2 + "rem";
    if (resTheme.websiteFor !== "recruitment") {
      tempDataContent.props.maxWidth = "80%";
      tempDataContent.children[0].children[0].variant = "element90";
    }
    const tempData = insert(resTempData, 1, tempDataContent);
    setData(tempData);
  };

  const renderRow = (row) => {
    return <UserRow key={row.id} data={row} />;
  };

  return (
    <Grid
      component="main"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F6F8FD",
      }}
    >
      {data
        .filter((v) => v.visibility)
        .map((row) => {
          return <React.Fragment key={row.id}>{renderRow(row)}</React.Fragment>;
        })}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  heightHeader: state.preview.heightHeader,
});

export default connect(mapStateToProps)(Index);

const tempDataContent = {
  id: "KUPhbHLCw",
  type: "row",
  props: {
    maxWidth: "60%",
  },
  children: [
    {
      id: "31ZVfDnAV",
      type: "column",
      props: {},
      children: [
        {
          id: "Wa2MSTlSv",
          type: "element",
          variant: "element91",
          // element91/registerV3 => shortcut : component in list elements library.js (RECRUITMENT)
          // element90/GeneralRegisterV3 => shortcut : component in list elements library.js (GENERAL)
        },
      ],
      colWidth: 12,
    },
  ],
  sectProps: {},
  visibility: true,
};
