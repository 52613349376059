import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { connect } from "react-redux";
// import "src/App.css";

function RichEditor({
  onChange,
  value,
  websiteFor,
  namePanel,
  minHeight = "300px",
  themePreview,
  ...props
}) {
  const buttonListArticle = [
    ["undo", "redo", "formatBlock"],
    ["font", "fontSize"],
    ["bold", "underline", "italic"], //, "strike", "subscript", "superscript"
    ["fontColor", "hiliteColor"],
    ["align", "list", "lineHeight"], //"lineHeight"
    ["outdent", "indent"],
    ["horizontalRule", "link", "image"], //"table", "image"
    // ["removeFormat"],
  ];

  const buttonListNonArticle = [
    ["undo", "redo"],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["fontColor", "hiliteColor"],
  ];

  const sortedFontOptions = [
    "Poppins",
    "Montserrat",
    "Lato",
    "Open Sans",
    "Roboto",
    "Oswald",
    "Playfair Display",
    "Raleway",
    "Merriweather",
    "Arial",
    "Noto Sans",
    "Abel",
    "Alegreya",
    "Dosis",
    "Arvo",
    // ...defaultFonts,
  ].sort();

  const format = ["p", "h1", "h2", "h3", "h4", "h5"];

  return (
    <div className="App">
      <SunEditor
        setContents={value}
        onChange={onChange}
        setOptions={{
          // className
          // buttonList:
          //   namePanel === "Article" ? buttonListArticle : buttonListNonArticle,
          defaultTag: "p",
          // defaultStyle: `font-family: ${themePreview?.font};`,
          formats: format,
          tagsBlacklist: "code",
          buttonList: buttonListArticle,
          minHeight: minHeight,
          showPathLabel: false,
          font: sortedFontOptions,
        }}
        // setDefaultStyle={`font-family: ${
        //   websiteFor === "recruitment" ? "Montserrat" : "Poppins"
        // }; line-height: 24px; letter-spacing: 0.02em;`}
        {...props}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  themePreview: state.preview.themePreview,
});
export default connect(mapStateToProps)(RichEditor);
