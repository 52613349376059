import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, Typography, styled } from "@mui/material";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

export default function ModalConfirmTest({
  open,
  dataDetail,
  onClose,
  loadingModal,
  handleStart,
}) {
  if (loadingModal) return <CircularProgress />;

  return (
    <React.Fragment>
      <CustomDialog open={open}>
        <DialogTitle>
          <Typography variant="h4">Instruksi Umum Pelaksanaan Tes</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <>
              <Typography color="black.main" sx={{ mt: 2 }}>
                Pada tahap ini, Anda akan menghadapi berbagai macam tes. Silakan
                Anda baca dengan teliti instruksi untuk masing-masing tes.
              </Typography>
              <br />
              <Typography color="black.main">
                Anda tidak diperkenankan untuk membuka tab lain dan meninggalkan
                tes bila Anda belum menyelesaikannnya.
              </Typography>
              <br />
              <Typography color="black.main">
                Silakan bekerja secara mandiri semaksimal mungkin dan tidak
                diperkenankan untuk bertanya pada orang lain.
              </Typography>
              <br />
              <Typography color="black.main">
                Perlu Anda ketahui, perilaku Anda selama tes akan diawasi kamera
                sehingga silakan untuk mempersiapkan diri dan berpakaian rapi.
              </Typography>
              <br />
              <Typography color="black.main">
                Bila diketahui ada kecurangan dalam proses pengerjaannya, kami
                akan mendiskualifikasi proses rekrutmen yang Anda ikuti dan Anda
                tidak diperkenankan untuk kembali mengikuti proses seleksi di
                PT. Pamapersada Nusantara.
              </Typography>
              <br />
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => handleStart(dataDetail)}>
            Accept
          </Button>
        </DialogActions>
      </CustomDialog>
    </React.Fragment>
  );
}
