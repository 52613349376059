import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  styled,
  Stack,
  TextField,
  Button,
  Chip,
  Divider,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import DialogConfirm from "./DialogConfirm";
// import InputMask from "react-input-mask";

const CustomInputA = ({
  label,
  name,
  onChange,
  value,
  defaultValue,
  required,
  ...rest
}) => {
  return (
    <TextField
      label={label}
      value={value}
      defaultValue={defaultValue}
      name={name}
      onChange={onChange}
      size="small"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      fullWidth
      style={{ marginBottom: "1rem" }}
      {...rest}
    />
  );
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const customIcon = {
  position: "absolute",
  right: "-4rem",
  bottom: "5%",
  top: "5%",
};

const SignA = () => {
  const [validation, setValidation] = useState(true);
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});

  const handleClickOpen = (type) => {
    setOpen(true);
    const props = {};
    if (type === "save") {
      props.text = "Apakah Anda yakin dengan aksi ini?";
      props.btn = "Simpan dan Lanjutkan";
      props.colorBtn = "primary";
    }
    if (type === "cancel") {
      props.text = "Apakah Anda yakin dengan aksi ini?";
      props.btn = "Mengundurkan Diri";
      props.colorBtn = "error";
    }
    setDialogProps(props);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid container>
      <Grid item sm={12}>
        <Typography variant="h5" sx={{ mb: "1rem" }}>
          Requirement Sign Contract
        </Typography>
        <Chip label="Batas Pengisian Rabu, 20 September 2023" color="primary" />
      </Grid>
      {/* <Grid container sx={{ mt: "1rem" }}>
        <Grid item sm={1}>
          <CheckCircle color="success" />
        </Grid>
        <Grid item sm={8}>
          <InputMask mask="99.999.999.9-999.999" onBlur={(e) => console.log(e.target.value)}>
            {(inputProps) => (
              <CustomInputA
                label="NPWP"
                placeholder="NPWP"
                name="npwp"
                {...inputProps}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item sm={3}>
          <Button component="label" variant="outlined" sx={{ ml: 3 }}>
            Upload
            <VisuallyHiddenInput type="file" />
          </Button>
        </Grid>
      </Grid> */}
      <Grid item sm={12}>
        <Typography variant="subtitle1" sx={{ mb: "1rem", fontWeight: "bold" }}>
          Rekening
        </Typography>
        <CustomInputA
          label="Nomor Rekening"
          placeholder="Nomor Rekening"
          name="rekening_number"
        />
        <CustomInputA
          label="Pemilik Rekening"
          placeholder="A.n. Pemilik Rekening"
          name="rekening_owner"
        />
        <CustomInputA
          label="Nama Bank"
          placeholder="Nama Bank"
          name="bank_name"
        />
        <CustomInputA
          label="Nama Cabang"
          placeholder="Nama Cabang"
          name="branch_name"
        />
        <Stack direction={"row"} spacing={1}>
          <CheckCircle color="success" />
          <Button component="label" variant="outlined" fullWidth>
            Upload Digital Scan sesuai Inputan di atas
            <VisuallyHiddenInput type="file" />
          </Button>
        </Stack>

        {/* <Stack sx={{ width: "100%" }}>
          <Button
            variant="contained"
            sx={{ m: "auto", mb: "1rem", width: "fit-content" }}
            onClick={() => handleClickOpen("save")}
          >
            Simpan dan Lanjutkan Proses
          </Button>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Button
            variant="contained"
            color="error"
            sx={{ m: "auto", mb: "1rem", width: "fit-content" }}
            onClick={() => handleClickOpen("cancel")}
          >
            Mengundurkan Diri
          </Button>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Button
            variant="outlined"
            sx={{ m: "auto", mb: "1rem", width: "fit-content" }}
          >
            Tutup
          </Button>
        </Stack> */}
      </Grid>
      <DialogConfirm open={open} handleClose={handleClose} {...dialogProps} />
    </Grid>
  );
};
export default SignA;
