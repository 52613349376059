export function getIdYoutubeVideo(url) {
  const index = url?.indexOf("?v=");
  if (index !== -1) {
    const videoIdStart = index + 3;
    const videoId = url?.substring(videoIdStart);
    return videoId;
  }

  const shortUrlIndex = url?.indexOf("youtu.be/");
  if (shortUrlIndex !== -1) {
    const videoIdStart = shortUrlIndex + 9;
    const videoId = url?.substring(videoIdStart);
    return videoId;
  }

  const embedUrlIndex = url?.indexOf("embed/");
  if (embedUrlIndex !== -1) {
    const videoIdStart = embedUrlIndex + 6;
    const videoId = url?.substring(videoIdStart);
    return videoId;
  }

  return null;
}
