import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
  styled,
  CircularProgress,
} from "@mui/material";
import { Email, Lock } from "@mui/icons-material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  loginForm,
  loginGoogle,
  setAuthenticated,
  setOpenLogin,
  setSnackbarOpen,
  emailCheckRegister,
  sendForgotPassword,
} from "src/redux/actions";
import { useNavigate } from "react-router";
import { FcGoogle } from "react-icons/fc";
import localforage from "localforage";
import { version } from "src/configs/globalVariable";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
    maxWidth: "350px",
  },
}));

const CustomCircularProgress = styled(CircularProgress)(
  ({ theme, loading }) => ({
    "&.MuiCircularProgress-root": {
      height: "20px !important",
      width: "20px !important",
      display: "flex",
      color: loading === "google" ? `${theme.palette.primary.main}` : "#ffff",
      marginRight: loading === "google" ? "0.5rem !important" : null,
    },
  })
);

const CustomTextField = styled(TextField)(({ theme }) => ({
  "&.Mui-focused": {
    border: `0.1px solid ${theme.palette.primary.main}`,
  },
  "&:hover fieldset": {
    border: `0.1px solid ${theme.palette.primary.main} !important`,
  },
}));

const Login = ({ open, onClose }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [form, setForm] = useState({ email: "", password: "" });
  const [showPass, setShowPass] = useState(false);
  const [getAppSetting, setAppSetting] = useState({});
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [isRegist, setIsRegist] = useState(false);
  const [isForgotPass, setIsForgotPass] = useState(false);

  useEffect(() => {
    fetchInfo();
    setValidate(false);
    setLoading(false);
    setIsRegist(false);
    setIsForgotPass(false);
  }, [open]);

  const fetchInfo = async () => {
    const resTheme = await localforage.getItem("theme");
    setAppSetting(resTheme);
  };

  const handleChange = (e) => {
    const copyForm = { ...form };
    copyForm[e.target.name] = e.target.value;
    setForm(copyForm);
  };

  const login = async () => {
    if (!form.email || !form.password) {
      setValidate(true);
      return;
    }
    setLoading("login");
    const resLogin = await loginForm(form);

    if (resLogin.code === 200) {
      setOpenLogin(false);
      setAuthenticated(resLogin.data.token);
      navigate(`/${version}/user/`);
      setTimeout(() => {
        window.location.reload();
      }, "500");
    } else if (resLogin.code === 400 || resLogin.code === 403) {
      setSnackbarOpen("error", resLogin.message);
    }
    setLoading(false);
  };

  function isValidEmail(email) {
    // Ekspresi reguler untuk memeriksa validitas alamat email
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailPattern.test(email);
  }

  const register = async () => {
    if (!isValidEmail(form.email)) {
      setSnackbarOpen("error", "Email tidak valid");
      return;
    }

    setLoading("login");
    const resLogin = await emailCheckRegister({ email: form.email });
    setLoading(false);

    if (resLogin.code === 409) {
      setSnackbarOpen("error", resLogin.message);
      return;
    }

    if (resLogin.code === 200) {
      await localforage.setItem("email", form.email);
      setOpenLogin(false);
      navigate(`/${version}/register`);
      return;
    }
  };

  const handleGoogle = async () => {
    setLoading("google");
    const resGoogle = await loginGoogle();

    if (resGoogle.code === 200) {
      setOpenLogin(false);
      setAuthenticated(resGoogle.data.token);
      navigate(`/${version}/user/`);
      setTimeout(() => {
        window.location.reload();
      }, "500");
    } else if (resGoogle.code === 400 || resGoogle.code === 403) {
      setSnackbarOpen("error", resGoogle.message);
    } else if (resGoogle.status === 403) {
      setOpenLogin(false);
      navigate(`/${version}/register`);
      return;
    }
    setLoading(false);
  };

  const handleSendForgot = async () => {
    form.domain = window.location.hostname;
    setLoading("reset-password");
    await sendForgotPassword(form);
    setSnackbarOpen("success", "Silahkan cek email anda");
    setIsForgotPass(false);
    setOpenLogin(false);
  };

  return (
    <CustomDialog open={open} onClose={onClose} maxWidth={"xs"}>
      <DialogContent>
        <Typography variant="h4" sx={{ mb: 4 }}>
          {isRegist ? "Daftar" : isForgotPass ? "Reset Password" : "Login"}
        </Typography>
        <CustomTextField
          placeholder="Email"
          type="email"
          name="email"
          variant="outlined"
          color="primary"
          hiddenLabel
          fullWidth
          error={validate && !form.email}
          helperText={validate && !form.email && "* email harap diisi"}
          value={form.email}
          onChange={handleChange}
          size="small"
          InputProps={{
            startAdornment: (
              <Email sx={{ color: `${theme.palette.primary.main}`, mr: 1 }} />
            ),
          }}
        />
        {!isRegist && !isForgotPass && (
          <>
            <CustomTextField
              placeholder="Kata sandi"
              type={showPass ? "text" : "password"}
              variant="outlined"
              color="primary"
              name="password"
              hiddenLabel
              fullWidth
              error={validate && !form.password}
              helperText={
                validate && !form.password && "* Kata sandi harap diisi"
              }
              sx={{ mt: 2 }}
              value={form.password}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <Lock
                    sx={{ color: `${theme.palette.primary.main}`, mr: 1 }}
                  />
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPass(!showPass)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPass ? (
                        <Visibility color="primary" />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              size="small"
            />
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "right",
                mb: 1,
                mt: 1,
                cursor: "pointer",
                color: "#3f51b5",
              }}
              onClick={() => setIsForgotPass(true)}
            >
              Lupa kata sandi?
            </Typography>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              fullWidth
              onClick={loading === "login" ? null : login}
              sx={{
                fontWeight: 700,
                borderRadius: "5rem",
                transition: "all linear .4s",
                mt: 3,
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              {loading === "login" ? <CustomCircularProgress /> : "Login"}
            </Button>
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              fullWidth
              onClick={loading === "google" ? null : handleGoogle}
              sx={{
                mt: 2,
                mb: 1,
                display: "flex",
                alignItems: "center",
                borderRadius: "5rem",
                transition: "all linear .4s",
                fontWeight: 600,
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              {loading === "google" ? (
                <CustomCircularProgress loading="google" />
              ) : (
                <FcGoogle
                  style={{
                    height: "40px !important",
                    marginRight: "0.5rem",
                  }}
                />
              )}

              <Typography variant="span">Daftar / Masuk</Typography>
            </Button>
            <Typography
              sx={{ fontSize: "14px", textAlign: "center", mb: "1rem" }}
            >
              Belum memiliki akun?{" "}
              <em
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#3f51b5",
                }}
                onClick={() => setIsRegist(true)}
              >
                Daftar
              </em>
            </Typography>
          </>
        )}
        {isRegist && (
          <>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              fullWidth
              onClick={loading === "login" ? null : register}
              sx={{
                fontWeight: 700,
                borderRadius: "5rem",
                transition: "all linear .4s",
                mt: 3,
                mb: 2,
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              {loading === "login" ? <CustomCircularProgress /> : "Daftar"}
            </Button>
            <Typography
              sx={{ fontSize: "14px", textAlign: "center", mb: "1rem" }}
            >
              Sudah memiliki akun?{" "}
              <em
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#3f51b5",
                }}
                onClick={() => setIsRegist(false)}
              >
                Login
              </em>
            </Typography>
          </>
        )}

        {isForgotPass && (
          <>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              fullWidth
              onClick={loading === "reset-password" ? null : handleSendForgot}
              sx={{
                fontWeight: 700,
                borderRadius: "5rem",
                transition: "all linear .4s",
                mt: 3,
                mb: 2,
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              {loading === "reset-password" ? (
                <CustomCircularProgress />
              ) : (
                "Reset Password"
              )}
            </Button>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",
                mb: "1rem",
                cursor: "pointer",
                color: "#3f51b5",
              }}
              onClick={() => setIsForgotPass(false)}
            >
              Kembali
            </Typography>
          </>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default Login;
