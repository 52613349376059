import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Divider,
  Card,
  Grid,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CustomDatePicker from "src/components/CustomDatePicker";
import {
  createOrganization,
  getOrganization,
  updateOrganization,
  deleteOrganization,
  setSnackbarOpen,
  getBiodata,
  updateBiodata,
} from "src/redux/actions";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const Organization = ({ handleChange }) => {
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [openForm, setOpenForm] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [form, setForm] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [validate, setValidate] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [bio, setBio] = useState({});
  const [validateTxtMin, setValidateTxtMin] = useState("");
  const [isEmptyOrg, setIsEmptyOrg] = useState(false);
  const validates = JSON.parse(localStorage.getItem("validates"));
  const val = parseInt(localStorage.getItem("value"));

  useEffect(() => {
    validates[val + 1] = true;
    localStorage.setItem("validates", JSON.stringify(validates));
    fetchOrg();
  }, []);

  useEffect(() => {
    if (bio?.is_organization === null) {
      setValidateTxtMin("* required");
    } else {
      setValidateTxtMin("");
    }
  }, [organizations, bio]);

  const fetchOrg = async () => {
    setLoading(true);
    const resOrg = await Promise.all([getOrganization(), getBiodata()]);
    setOrganizations(resOrg[0].data || []);
    setIsEmptyOrg(resOrg[0].data?.length > 0 ? false : true);
    // resOrg[1].data[0].city_ktp = resOrg[1].data[0].city_ktp?.city_ktp;
    // resOrg[1].data[0].kecamatan_ktp = resOrg[1].data[0].kecamatan_ktp?.kecamatan_ktp;
    // resOrg[1].data[0].kelurahan_ktp = resOrg[1].data[0].kelurahan_ktp?.kelurahan_ktp;
    // resOrg[1].data[0].province_ktp = resOrg[1].data[0].province_ktp?.province_ktp;
    resOrg[1].data[0].city = resOrg[1].data[0].city?.city;
    resOrg[1].data[0].kecamatan = resOrg[1].data[0].kecamatan?.kecamatan;
    resOrg[1].data[0].kelurahan = resOrg[1].data[0].kelurahan?.kelurahan;
    resOrg[1].data[0].province = resOrg[1].data[0].province?.province;
    setBio(resOrg[1].data[0] || {});
    setLoading(false);
  };

  const handleDateChange = (val, name) => {
    setForm({ ...form, [name]: val });
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleEdit = (d) => {
    setForm(d);
    setOpenForm("edit");
  };

  const handleDelete = (d) => {
    setForm(d);
    setAlertOpen(true);
  };

  const handleSubmitDelete = async () => {
    await deleteOrganization(form);
    setForm({});
    setSnackbarOpen("success", "Data berhasil dihapus");
    setAlertOpen(false);
    fetchOrg();
  };

  function tambahkanSatuHari(tanggal) {
    let tanggalBaru = new Date(tanggal);
    tanggalBaru.setHours(tanggalBaru.getHours() + 12);
    return tanggalBaru;
  }

  const handleSave = async () => {
    setValidate(true);
    const conf =
      !form.finished_at ||
      !form.started_at ||
      !form.position ||
      !form.organization_field ||
      !form.organization_name;

    if (conf) return;

    form.started_at = tambahkanSatuHari(form.started_at);
    form.finished_at = tambahkanSatuHari(form.finished_at);
    setLoadingButton(true);
    if (openForm === "new") {
      await createOrganization(form);
    } else {
      await updateOrganization(form);
    }
    setLoadingButton(false);
    setValidate(false);
    setSnackbarOpen("success", "Data disimpan");
    setOpenForm("");
    setForm({});
    fetchOrg();
  };

  const handleUpdateBiodata = async (key, val) => {
    setLoadingCheck(true);
    const newBio = { ...bio, [key]: val };
    await updateBiodata(newBio);
    setBio(newBio);
    setLoadingCheck(false);
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Pengalaman Organisasi</b>
      </Typography>
      <Grid container sx={{ mt: "4rem" }}>
        <Grid item md={8} sm={12} sx={{ m: "auto 0" }}>
          <Typography>
            Apakah Anda memiliki pengalaman dalam berorganisasi?
          </Typography>
        </Grid>
        <Grid item md={2} sm={12}>
          {loadingCheck && <CircularProgress size={24} />}
          {!loadingCheck && (
            <Stack direction={"row"} spacing={2}>
              <FormControlLabel
                label="Tidak"
                error={validate && bio.is_organization === null}
                control={
                  <Checkbox
                    checked={bio?.is_organization === false}
                    onClick={(e) =>
                      handleUpdateBiodata("is_organization", false)
                    }
                  />
                }
              />
              <FormControlLabel
                label="Ya"
                error={validate && form.is_organization === null}
                control={
                  <Checkbox
                    checked={bio?.is_organization}
                    onClick={(e) =>
                      handleUpdateBiodata("is_organization", true)
                    }
                  />
                }
              />
            </Stack>
          )}
        </Grid>
        {validateTxtMin && (
          <Grid item xs={12}>
            <Typography
              variant="caption"
              sx={{ color: "#ff0000", mb: 4, mt: 4, textAlign: "center" }}
            >
              {validateTxtMin}
            </Typography>
          </Grid>
        )}
      </Grid>

      {bio?.is_organization === true && (
        <>
          <Button
            variant="contained"
            sx={{ display: "flex", m: "auto 0 1rem auto" }}
            onClick={() => setOpenForm("new")}
          >
            Tambah
          </Button>
          <Grid container sx={{ mb: "1rem" }}>
            <Grid item sm={3} sx={{ m: "auto" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Nama Organisasi
              </Typography>
            </Grid>
            <Grid item sm={3} sx={{ m: "auto" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Bidang Organisasi
              </Typography>
            </Grid>
            <Grid item sm={3} sx={{ m: "auto" }}>
              <Typography sx={{ fontWeight: "bold" }}>Jabatan</Typography>
            </Grid>
            <Grid item sm={3} sx={{ m: "auto" }}>
              <Typography sx={{ fontWeight: "bold" }}> </Typography>
            </Grid>
          </Grid>
          {isEmptyOrg && (
            <Typography
              variant="caption"
              sx={{ color: "#ff0000", mb: 4, mt: 4, textAlign: "center" }}
            >
              * required write down at least 1 organization
            </Typography>
          )}
          {organizations.map((org) => (
            <Card
              id={org.bio_organization_id}
              sx={{ backgroundColor: "#FFF", p: 2, borderRadius: "16px" }}
            >
              <Grid container>
                <Grid item sm={3} sx={{ m: "auto" }}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    {org.organization_name}
                  </Typography>
                </Grid>
                <Grid item sm={3} sx={{ m: "auto" }}>
                  <Typography sx={{ fontSize: "14px" }}>
                    {org.organization_field}
                  </Typography>
                </Grid>
                <Grid item sm={3} sx={{ m: "auto" }}>
                  <Typography sx={{ fontSize: "14px" }}>
                    {org.position}
                  </Typography>
                </Grid>
                <Grid item sm={3} sx={{ m: "auto" }}>
                  <Stack direction="row" justifyContent={"end"} spacing={1}>
                    <IconButton onClick={() => handleEdit(org)}>
                      <EditRoundedIcon />{" "}
                    </IconButton>
                    <IconButton onClick={() => handleDelete(org)}>
                      <DeleteRoundedIcon />{" "}
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          ))}
        </>
      )}
      <Divider sx={{ mt: 2, mb: 2 }} />
      {openForm && (
        <>
          <CustomInput
            label="Nama Organisasi"
            name="organization_name"
            onChange={handleChangeInput}
            value={form.organization_name}
            error={validate && !form.organization_name}
          />
          <CustomInput
            label="Bidang Organisasi"
            name="organization_field"
            onChange={handleChangeInput}
            value={form.organization_field}
            error={validate && !form.organization_field}
          />
          <CustomInput
            label="Jabatan"
            name="position"
            onChange={handleChangeInput}
            value={form.position}
            error={validate && !form.position}
          />
          <CustomDatePicker
            label="Tanggal Mulai"
            onChange={handleDateChange}
            name="started_at"
            value={form?.started_at ? form.started_at : null}
            error={validate && !form.started_at}
          />
          <CustomDatePicker
            label="Tanggal Selesai"
            onChange={handleDateChange}
            name="finished_at"
            value={form?.finished_at ? form.finished_at : null}
            error={validate && !form.finished_at}
          />
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"end"}
            sx={{ my: "1rem" }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setOpenForm("");
                setForm({});
              }}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={loadingButton}
              startIcon={loadingButton ? <CircularProgress /> : null}
            >
              Simpan
            </Button>
          </Stack>
        </>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: "1.5rem" }}
      >
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val - 1] ? 1 : 0,
          }}
          onClick={(e) => handleChange(e, val - 1)}
          disabled={!validates[val - 1]}
          startIcon={<KeyboardArrowLeft />}
        >
          Data Pendidikan
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val + 1] ? 1 : 0,
          }}
          onClick={(e) => {
            const conf =
              validateTxtMin || (bio?.is_organization === true && isEmptyOrg);
            if (conf) return;
            handleChange(e, val + 1);
          }}
          disabled={!validates[val + 1]}
          endIcon={<KeyboardArrowRight />}
        >
          Pengalaman Kerja
        </Button>
      </Stack>

      <CustomDialog open={alertOpen} fullWidth maxWidth="xs">
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent>
          Data <b>{form.organization_name}</b> akan dihapus
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setAlertOpen(false);
              setForm({});
            }}
          >
            Batal
          </Button>
          <Button variant="contained" onClick={handleSubmitDelete}>
            Ya, Hapus
          </Button>
        </DialogActions>
      </CustomDialog>
    </Container>
  );
};

export default Organization;
