import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Avatar,
  Button,
  Divider,
  IconButton,
  CircularProgress,
  Stack,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import CustomDatePicker from "src/components/CustomDatePicker";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import {
  refEthnic,
  refCountries,
  refReligion,
  getRefRegion,
  getBiodata,
  updateBiodata,
  setSnackbarOpen,
  uploadFileJp,
  getRef,
} from "src/redux/actions";
import { loadImage } from "src/functions/utils";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  inputNumberOnly,
  inputAlphabetOnly,
} from "src/functions/inputNumberOnly";

const baseURL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:5000/"
    : window.location.origin + "/";

const PersonalData = ({ handleChange }) => {
  const [loading, setLoading] = useState(true);
  const [ethnics, setEthnics] = useState([]);
  const [countries, setCountries] = useState([]);
  const [religions, setReligions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [kecamatan, setKecamatan] = useState([]);
  const [kelurahan, setKelurahan] = useState([]);
  const [zipCode, setZipCode] = useState([]);
  const [provincesKtp, setProvincesKtp] = useState([]);
  const [citiesKtp, setCitiesKtp] = useState([]);
  const [kecamatanKtp, setKecamatanKtp] = useState([]);
  const [kelurahanKtp, setKelurahanKtp] = useState([]);
  const [zipCodeKtp, setZipCodeKtp] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [birthCities, setBirthCities] = useState([]);
  const [refGender, setRefGender] = useState([]);
  const [form, setForm] = useState({});
  const [validate, setValidate] = useState(false);
  const [pathPhoto, setPathPhoto] = useState(null);
  const [validateTxt] = useState("* required");
  const validates = JSON.parse(localStorage.getItem("validates"));
  const val = parseInt(localStorage.getItem("value"));

  useEffect(() => {
    fetchRef();
  }, []);

  const fetchRef = async () => {
    setLoading(true);
    const resRef = await Promise.all([
      refEthnic(),
      refCountries(),
      refReligion(),
      getRefRegion(),
      getRef({ type: "refMaritalStatus" }),
      getRefRegion({ province: "all" }),
      getRef({ type: "refGender" }),
    ]);
    setEthnics(resRef[0].data);
    setCountries(resRef[1].data);
    setReligions(resRef[2].data);
    setProvinces(resRef[3].data);
    setProvincesKtp(resRef[3].data);
    setMaritalStatus(resRef[4].data);
    setBirthCities(resRef[5].data);
    setRefGender(resRef[6].data);
    fetchBio();
  };

  const fetchBio = async () => {
    setLoading(true);
    const getBio = await getBiodata();
    const setRegionData = async (regionType, regionData, setRegionFunction) => {
      if (regionData) {
        const resRegion = await getRefRegion({
          [regionType]: regionData[regionType],
        });
        setRegionFunction(resRegion.data);
      }
    };

    const {
      province,
      city,
      kecamatan,
      kelurahan,
      province_ktp,
      city_ktp,
      kecamatan_ktp,
      kelurahan_ktp,
    } = getBio.data[0];

    setRegionData("province", province, setCities);
    setRegionData("city", city, setKecamatan);
    setRegionData("kecamatan", kecamatan, setKelurahan);
    setRegionData("kelurahan", kelurahan, setZipCode);

    setRegionData("province", province_ktp, setCitiesKtp);
    setRegionData("city", city_ktp, setKecamatanKtp);
    setRegionData("kecamatan", kecamatan_ktp, setKelurahanKtp);
    setRegionData("kelurahan", kelurahan_ktp, setZipCodeKtp);

    console.log(getBio.data[0]);
    setPathPhoto(getBio.data[0].photo);
    const resNewGet = await loadImage(getBio.data[0].photo);
    // console.log(resNewGet);
    getBio.data[0].photo = resNewGet;
    setForm(getBio.data[0]);
    setLoading(false);
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCheck = (val, key) => {
    setForm({ ...form, [key]: val });
  };

  const handleDateChange = (val, key) => {
    setForm({ ...form, [key]: val });
  };

  const handleSelectAuto = async (val, key) => {
    setForm({ ...form, [key]: val[key] });
    if (key === "gender") {
      setForm({ ...form, gender_code: val.gender_code, gender: val });
    }
    if (key === "country") {
      setForm({ ...form, country_id: val.code, country: val });
    }
    if (key === "religion") {
      setForm({ ...form, religion_id: val.code, religion: val });
    }
    if (key === "ethnic") {
      setForm({ ...form, ethnic_id: val.ethnic_id, ethnic: val.ethnic_id });
    }
    if (key === "birth_place") {
      setForm({ ...form, birth_place: val.city });
    }
    if (key === "kode_pos") {
      setForm({
        ...form,
        zipcode: val.kode_pos,
        id_pos: val.id_pos,
        kodepos: val,
      });
    }
    if (key === "kode_pos_ktp") {
      setForm({
        ...form,
        zipcode_ktp: val.kode_pos,
        id_pos_ktp: val.id_pos,
        kodepos_ktp: val,
      });
    }

    if (
      key === "province" ||
      key === "city" ||
      key === "kecamatan" ||
      key === "kelurahan"
    ) {
      setForm({ ...form, [key]: val });
      const resRegion = await getRefRegion({ [key]: val[key] });
      if (key === "province") setCities(resRegion.data);
      if (key === "city") setKecamatan(resRegion.data);
      if (key === "kecamatan") setKelurahan(resRegion.data);
      if (key === "kelurahan") setZipCode(resRegion.data);
    }

    if (
      key === "province_ktp" ||
      key === "city_ktp" ||
      key === "kecamatan_ktp" ||
      key === "kelurahan_ktp"
    ) {
      const newKey = key.split("_")[0];
      setForm({ ...form, [key]: val });
      const resRegion = await getRefRegion({ [newKey]: val[newKey] });
      if (key === "province_ktp") setCitiesKtp(resRegion.data);
      if (key === "city_ktp") setKecamatanKtp(resRegion.data);
      if (key === "kecamatan_ktp") setKelurahanKtp(resRegion.data);
      if (key === "kelurahan_ktp") setZipCodeKtp(resRegion.data);
    }
  };

  const checkRequirement = () => {
    if (
      form.photo?.indexOf("null") !== -1 ||
      !form.fullname ||
      !form.nickname ||
      !form.gender_code ||
      !form.birth_place ||
      !form.birth_date ||
      !form.ethnic ||
      !form.country ||
      !form.religion ||
      !form.marital_status_id ||
      !form.height ||
      !form.weight ||
      !form.home_phone ||
      !form.mobile_phone ||
      !form.address_ktp ||
      !form.province_ktp.province ||
      !form.city_ktp.city ||
      !form.kecamatan_ktp.kecamatan ||
      !form.kelurahan_ktp.kelurahan ||
      !form.kodepos_ktp ||
      !form.address ||
      !form.province.province ||
      !form.city.city ||
      !form.kecamatan.kecamatan ||
      !form.kelurahan.kelurahan ||
      !form.kodepos ||
      form.is_social_instagram === null ||
      form.is_social_facebook === null ||
      form.is_social_linkedin === null ||
      form.is_social_twitter === null ||
      form.is_social_other === null ||
      (form.is_social_instagram && !form.social_instagram) ||
      (form.is_social_facebook && !form.social_facebook) ||
      (form.is_social_linkedin && !form.social_linkedin) ||
      (form.is_social_twitter && !form.social_twitter) ||
      (form.is_social_other && !form.social_other)
    ) {
      setValidate(true);
      setSnackbarOpen("error", "Terdapat field yang masih kosong.");
      return true;
    } else {
      setValidate(false);
      return false;
    }
  };

  function tambahkanSatuHari(tanggal) {
    let tanggalBaru = new Date(tanggal);
    tanggalBaru.setHours(tanggalBaru.getHours() + 12);
    return tanggalBaru;
  }

  const handleSave = async () => {
    if (checkRequirement()) return;
    const copyForm = { ...form };
    // console.log(copyForm);
    copyForm.city = copyForm.city?.city;
    copyForm.kecamatan = copyForm.kecamatan?.kecamatan;
    copyForm.kelurahan = copyForm.kelurahan?.kelurahan;
    copyForm.province = copyForm.province?.province;
    copyForm.city_ktp = copyForm.city_ktp?.city;
    copyForm.kecamatan_ktp = copyForm.kecamatan_ktp?.kecamatan;
    copyForm.kelurahan_ktp = copyForm.kelurahan_ktp?.kelurahan;
    copyForm.province_ktp = copyForm.province_ktp?.province;
    copyForm.birth_date = tambahkanSatuHari(copyForm.birth_date);
    copyForm.ethnic =
      typeof form?.ethnic === "string" ? form?.ethnic : form?.ethnic?.ethnic_id;
    copyForm.photo = pathPhoto;
    const res = await updateBiodata(copyForm);
    if (res.code === 200) {
      validates[val + 1] = true;
      localStorage.setItem("validates", JSON.stringify(validates));
      setSnackbarOpen("success", "Update data berhasil.");
      fetchBio();
    } else {
      setSnackbarOpen(
        "error",
        "Update data belum berhasil, mohon dicoba kembali."
      );
    }
  };

  const handleUpload = async (files, key) => {
    console.log(form);
    if (files.length) {
      const resUpload = await uploadFileJp(files[0]);
      if (typeof resUpload === "string") {
        setSnackbarOpen("error", resUpload);
        return;
      }
      setPathPhoto(resUpload.data?.path);
      const resNewGet = await loadImage(resUpload.data?.path);
      const copyForm = { ...form };
      copyForm.photo = resNewGet;
      setForm(copyForm);
      // copyForm[key] = resUpload.data?.path;
      // copyForm.city = copyForm.city?.city;
      // copyForm.kecamatan = copyForm.kecamatan?.kecamatan;
      // copyForm.kelurahan = copyForm.kelurahan?.kelurahan;
      // copyForm.province = copyForm.province?.province;
      // copyForm.ethnic = form?.ethnic_id || form?.ethnic?.ethnic_id;
      // await updateBiodata(copyForm);
      setSnackbarOpen("success", "Upload Image berhasil");
      // fetchBio();
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Data Diri</b>
      </Typography>
      <Stack sx={{ display: "flex" }}>
        <IconButton component="label" sx={{ m: "auto", display: "flex" }}>
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={(e) => handleUpload(e.target.files, "photo")}
          />
          <Avatar
            alt={form?.fullname}
            src={form?.photo}
            sx={{ width: 160, height: 160 }}
          />
        </IconButton>
        {validate && form.photo?.indexOf("null") !== -1 && (
          <Typography
            variant="caption"
            sx={{ color: "#ff0000", mb: 4, mt: 0, textAlign: "center" }}
          >
            {validateTxt}
          </Typography>
        )}
      </Stack>
      <CustomInput
        label="Nama Lengkap (Sesuai KTP)"
        name="fullname"
        onChange={handleChangeInput}
        value={form?.fullname}
        error={validate && !form.fullname}
        helpertext={validate && !form.fullname && validateTxt}
        onKeyDown={inputAlphabetOnly}
      />
      <CustomInput
        label="Nama Panggilan"
        name="nickname"
        onChange={handleChangeInput}
        value={form?.nickname}
        error={validate && !form.nickname}
        helpertext={validate && !form.nickname && validateTxt}
      />
      {/* <CustomInput label="Nomer Identitas" /> */}
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Jenis Kelamin
      </Typography>
      <CustomAutoComplete
        name="gender_code"
        optionsArr={refGender}
        keyLabel={"gender_name"}
        handleSelect={handleSelectAuto}
        value={refGender.filter((g) => g.gender_code === form.gender_code)[0]}
        register="true"
        style={{ marginBottom: "1rem" }}
        error={validate && !form.gender_code}
        helpertext={validate && !form.gender_code && validateTxt}
      />
      {/* <CustomInput
        label="Tempat Lahir"
        name="birth_place"
        onChange={handleChangeInput}
        value={form?.birth_place}
      /> */}
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Tempat Lahir
      </Typography>
      <CustomAutoComplete
        name="birth_place"
        optionsArr={birthCities}
        keyLabel={"city"}
        handleSelect={handleSelectAuto}
        value={birthCities?.filter((bc) => bc.city === form?.birth_place)[0]}
        register="true"
        style={{ marginBottom: "1rem" }}
        error={validate && !form.birth_place}
        helpertext={validate && !form.birth_place && validateTxt}
      />
      <CustomDatePicker
        label="Tanggal Lahir"
        name="birth_date"
        onChange={handleDateChange}
        value={form?.birth_date}
        error={validate && !form.birth_date}
        helpertext={validate && !form.birth_date && validateTxt}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>Suku</Typography>
      <CustomAutoComplete
        name="ethnic"
        optionsArr={ethnics}
        keyLabel={"ethnic_name"}
        handleSelect={handleSelectAuto}
        value={ethnics.filter((e) => e.ethnic_id === form.ethnic)[0]}
        register="true"
        style={{ marginBottom: "1rem" }}
        error={validate && !form.ethnic}
        helpertext={validate && !form.ethnic && validateTxt}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Kewarganegaraan
      </Typography>
      <CustomAutoComplete
        name="country"
        optionsArr={countries}
        keyLabel={"name"}
        handleSelect={handleSelectAuto}
        value={form?.country}
        register="true"
        style={{ marginBottom: "1rem" }}
        error={validate && !form.country}
        helpertext={validate && !form.country && validateTxt}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>Agama</Typography>
      <CustomAutoComplete
        name="religion"
        optionsArr={religions}
        keyLabel={"name"}
        handleSelect={handleSelectAuto}
        value={form?.religion}
        register="true"
        style={{ marginBottom: "1rem" }}
        error={validate && !form.religion}
        helpertext={validate && !form.religion && validateTxt}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Status Pernikahan
      </Typography>
      <CustomAutoComplete
        name="marital_status_id"
        optionsArr={maritalStatus}
        keyLabel={"marital_status_name"}
        handleSelect={handleSelectAuto}
        value={
          maritalStatus.filter(
            (m) => m.marital_status_id === form.marital_status_id
          )[0]
        }
        register="true"
        style={{ marginBottom: "1rem" }}
        error={validate && !form.marital_status_id}
        helpertext={validate && !form.marital_status_id && validateTxt}
      />
      <CustomInput
        label="Tinggi Badan (cm)"
        name="height"
        onChange={handleChangeInput}
        value={form?.height}
        error={validate && !form.height}
        helpertext={validate && !form.height && validateTxt}
        onKeyDown={inputNumberOnly}
      />
      <CustomInput
        label="Berat Badan (kg)"
        name="weight"
        onChange={handleChangeInput}
        value={form?.weight}
        error={validate && !form.weight}
        helpertext={validate && !form.weight && validateTxt}
        onKeyDown={inputNumberOnly}
      />
      <CustomInput
        label="Nomor Telepon"
        name="home_phone"
        onChange={handleChangeInput}
        value={form?.home_phone}
        error={validate && !form.home_phone}
        helpertext={validate && !form.home_phone && validateTxt}
      />
      <CustomInput
        label="Nomor Whatsapp"
        name="mobile_phone"
        onChange={handleChangeInput}
        value={form?.mobile_phone}
        error={validate && !form.mobile_phone}
        helpertext={validate && !form.mobile_phone && validateTxt}
      />
      <CustomInput label="Email" disabled value={form?.email} />
      <Divider />
      <Typography sx={{ fontSize: "20px", mt: 2, mb: 2 }}>
        <b>Alamat</b>
      </Typography>

      <Stack
        sx={{
          border: "1px solid #7f8bce",
          borderRadius: "6px",
          p: "1rem",
          mt: "1rem",
          mb: "1rem",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
          Alamat KTP
        </Typography>
        <CustomInput
          label="Alamat Lengkap"
          multiline
          rows={4}
          name="address_ktp"
          onChange={handleChangeInput}
          value={form?.address_ktp}
          error={validate && !form.address_ktp}
          helpertext={validate && !form.address_ktp && validateTxt}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Provinsi
        </Typography>
        <CustomAutoComplete
          name="province_ktp"
          optionsArr={provincesKtp}
          keyLabel={"province"}
          handleSelect={handleSelectAuto}
          value={form?.province_ktp?.province ? form.province_ktp : null}
          register="true"
          style={{ marginBottom: "1rem" }}
          error={validate && !form.province_ktp.province}
          helpertext={validate && !form.province_ktp.province && validateTxt}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kota/Kabupaten
        </Typography>
        <CustomAutoComplete
          name="city_ktp"
          optionsArr={citiesKtp}
          keyLabel={"city"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.province_ktp}
          value={form?.city_ktp?.city ? form.city_ktp : null}
          error={validate && !form.city_ktp.city}
          helpertext={validate && !form.city_ktp.city && validateTxt}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kecamatan
        </Typography>
        <CustomAutoComplete
          name="kecamatan_ktp"
          optionsArr={kecamatanKtp}
          keyLabel={"kecamatan"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.city_ktp}
          value={form?.kecamatan_ktp?.kecamatan ? form.kecamatan_ktp : null}
          error={validate && !form.kecamatan_ktp.kecamatan}
          helpertext={validate && !form.kecamatan_ktp.kecamatan && validateTxt}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kelurahan
        </Typography>
        <CustomAutoComplete
          name="kelurahan_ktp"
          optionsArr={kelurahanKtp}
          keyLabel={"kelurahan"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.kecamatan_ktp}
          value={form?.kelurahan_ktp?.kelurahan ? form.kelurahan_ktp : null}
          error={validate && !form.kelurahan_ktp.kelurahan}
          helpertext={validate && !form.kelurahan_ktp.kelurahan && validateTxt}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kode Pos
        </Typography>
        <CustomAutoComplete
          name="kode_pos_ktp"
          optionsArr={zipCodeKtp}
          keyLabel={"kode_pos"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.kelurahan_ktp}
          value={form?.kodepos_ktp}
          error={validate && !form.kodepos_ktp}
          helpertext={validate && !form.kodepos_ktp && validateTxt}
        />
      </Stack>

      <Stack
        sx={{
          border: "1px solid #7f8bce",
          borderRadius: "6px",
          p: "1rem",
          mb: "1rem",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
          Alamat Domisili
        </Typography>
        <CustomInput
          label="Alamat Lengkap"
          multiline
          rows={4}
          name="address"
          onChange={handleChangeInput}
          value={form?.address}
          error={validate && !form.address}
          helpertext={validate && !form.address && validateTxt}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Provinsi
        </Typography>
        <CustomAutoComplete
          name="province"
          optionsArr={provinces}
          keyLabel={"province"}
          handleSelect={handleSelectAuto}
          value={form?.province?.province ? form.province : null}
          register="true"
          style={{ marginBottom: "1rem" }}
          error={validate && !form.province.province}
          helpertext={validate && !form.province.province && validateTxt}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kota/Kabupaten
        </Typography>
        <CustomAutoComplete
          name="city"
          optionsArr={cities}
          keyLabel={"city"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.province}
          value={form?.city?.city ? form.city : null}
          error={validate && !form.city.city}
          helpertext={validate && !form.city.city && validateTxt}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kecamatan
        </Typography>
        <CustomAutoComplete
          name="kecamatan"
          optionsArr={kecamatan}
          keyLabel={"kecamatan"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.city}
          value={form?.kecamatan?.kecamatan ? form.kecamatan : null}
          error={validate && !form.kecamatan.kecamatan}
          helpertext={validate && !form.kecamatan.kecamatan && validateTxt}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kelurahan
        </Typography>
        <CustomAutoComplete
          name="kelurahan"
          optionsArr={kelurahan}
          keyLabel={"kelurahan"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.kecamatan}
          value={form?.kelurahan?.kelurahan ? form.kelurahan : null}
          error={validate && !form.kelurahan.kelurahan}
          helpertext={validate && !form.kelurahan.kelurahan && validateTxt}
        />
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Kode Pos
        </Typography>
        <CustomAutoComplete
          name="kode_pos"
          optionsArr={zipCode}
          keyLabel={"kode_pos"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          disabled={!form?.kelurahan}
          value={form?.kodepos}
          error={validate && !form.kodepos}
          helpertext={validate && !form.kodepos && validateTxt}
        />
      </Stack>

      <Typography sx={{ fontSize: "20px", mt: 2, mb: 1 }}>
        <b>Media Sosial</b>
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Typography
            sx={{
              color:
                validate && form.is_social_instagram === null
                  ? "#d32f2f"
                  : "inherit",
            }}
          >
            Instagram
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <Stack direction={"row"} spacing={2}>
            <FormControlLabel
              label="Tidak Memiliki"
              error={validate && form.is_social_instagram === null}
              control={
                <Checkbox
                  checked={form?.is_social_instagram === false}
                  onClick={(e) => handleCheck(false, "is_social_instagram")}
                />
              }
            />
            <FormControlLabel
              label="Memiliki"
              error={validate && form.is_social_instagram === null}
              control={
                <Checkbox
                  checked={form?.is_social_instagram}
                  onClick={(e) => handleCheck(true, "is_social_instagram")}
                />
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {form?.is_social_instagram && (
            <CustomInput
              label=""
              name="social_instagram"
              onChange={handleChangeInput}
              value={form?.social_instagram}
              error={validate && !form.social_instagram}
              helpertext={validate && !form.social_instagram && validateTxt}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Typography
            sx={{
              color:
                validate && form.is_social_linkedin === null
                  ? "#d32f2f"
                  : "inherit",
            }}
          >
            LinkedIn
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              label="Tidak Memiliki"
              error={validate && form.is_social_linkedin === null}
              control={
                <Checkbox
                  checked={form?.is_social_linkedin === false}
                  onClick={(e) => handleCheck(false, "is_social_linkedin")}
                />
              }
            />
            <FormControlLabel
              label="Memiliki"
              error={validate && form.is_social_linkedin === null}
              control={
                <Checkbox
                  checked={form?.is_social_linkedin}
                  onClick={(e) => handleCheck(true, "is_social_linkedin")}
                />
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {form?.is_social_linkedin && (
            <CustomInput
              label=""
              name="social_linkedin"
              onChange={handleChangeInput}
              value={form?.social_linkedin}
              error={validate && !form.social_linkedin}
              helpertext={validate && !form.social_linkedin && validateTxt}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Typography
            sx={{
              color:
                validate && form.is_social_facebook === null
                  ? "#d32f2f"
                  : "inherit",
            }}
          >
            Facebook
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <Stack direction={"row"} spacing={2}>
            <FormControlLabel
              label="Tidak Memiliki"
              error={validate && form.is_social_facebook === null}
              control={
                <Checkbox
                  checked={form?.is_social_facebook === false}
                  onClick={(e) => handleCheck(false, "is_social_facebook")}
                />
              }
            />
            <FormControlLabel
              label="Memiliki"
              error={validate && form.is_social_facebook === null}
              control={
                <Checkbox
                  checked={form?.is_social_facebook}
                  onClick={(e) => handleCheck(true, "is_social_facebook")}
                />
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {form?.is_social_facebook && (
            <CustomInput
              label=""
              name="social_facebook"
              onChange={handleChangeInput}
              value={form?.social_facebook}
              error={validate && !form.social_facebook}
              helpertext={validate && !form.social_facebook && validateTxt}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Typography
            sx={{
              color:
                validate && form.is_social_twitter === null
                  ? "#d32f2f"
                  : "inherit",
            }}
          >
            Twitter / X
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              label="Tidak Memiliki"
              error={validate && form.is_social_twitter === null}
              control={
                <Checkbox
                  checked={form?.is_social_twitter === false}
                  onClick={(e) => handleCheck(false, "is_social_twitter")}
                />
              }
            />
            <FormControlLabel
              label="Memiliki"
              error={validate && form.is_social_twitter === null}
              control={
                <Checkbox
                  checked={form?.is_social_twitter}
                  onClick={(e) => handleCheck(true, "is_social_twitter")}
                />
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {form?.is_social_twitter && (
            <CustomInput
              label=""
              name="social_twitter"
              onChange={handleChangeInput}
              value={form?.social_twitter}
              error={validate && !form.social_twitter}
              helpertext={validate && !form.social_twitter && validateTxt}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Typography
            sx={{
              color:
                validate && form.is_social_other === null
                  ? "#d32f2f"
                  : "inherit",
            }}
          >
            Media Sosial Lainnya
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <Stack direction={"row"} spacing={2}>
            <FormControlLabel
              label="Tidak Memiliki"
              error={validate && form.is_social_other === null}
              control={
                <Checkbox
                  checked={form?.is_social_other === false}
                  onClick={(e) => handleCheck(false, "is_social_other")}
                />
              }
            />
            <FormControlLabel
              label="Memiliki"
              error={validate && form.is_social_other === null}
              control={
                <Checkbox
                  checked={form?.is_social_other}
                  onClick={(e) => handleCheck(true, "is_social_other")}
                />
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {form?.is_social_other && (
            <CustomInput
              label=""
              name="social_other"
              onChange={handleChangeInput}
              value={form?.social_other}
              error={validate && !form.social_other}
              helpertext={validate && !form.social_other && validateTxt}
            />
          )}
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: "1.5rem" }}
      >
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val - 1] ? 1 : 0,
          }}
          onClick={(e) => handleChange(e, val - 1)}
          disabled={!validates[val - 1]}
          startIcon={<KeyboardArrowLeft />}
        >
          Back
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Simpan
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val + 1] ? 1 : 0,
          }}
          onClick={(e) => {
            if (checkRequirement()) return;
            handleChange(e, val + 1);
          }}
          disabled={!validates[val + 1]}
          endIcon={<KeyboardArrowRight />}
        >
          Data Keluarga
        </Button>
      </Stack>
    </Container>
  );
};

export default PersonalData;
