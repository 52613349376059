import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { articleAPI, setIsActiveNavbar, setIsPreview } from "src/redux/actions";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import localforage from "localforage";
import {
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  Stack,
  Chip,
  Breadcrumbs,
  Link,
  Skeleton,
} from "@mui/material";
import CardArt01 from "src/components/Cards/CardArt01";
import { dataArt, domain } from "src/components/Layout/dummy";
import { parsedDate } from "src/functions/parsedDate";
import "src/App.css";
import { version } from "src/configs/globalVariable";

const DetailArticle = ({ libPages }) => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [articleObj, setArticleObj] = useState({});
  const [websiteFor, setWebsiteFor] = useState("");

  useEffect(() => {
    checkParamsUrl();
    setIsPreview(false);
    fetchData();
  }, []);

  const checkParamsUrl = async () => {
    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);
    setIsActiveNavbar("Article");
  };

  const fetchData = async () => {
    // when load the data
    const arr = [{ ...dataArt }, { ...dataArt }, { ...dataArt }];
    setArticle(arr);
    const resTheme = await localforage.getItem("theme");
    setWebsiteFor(resTheme.websiteFor);

    const resArticleList = await articleAPI("get", { domain });
    const resArticleObj = await articleAPI("get/detail", {
      articleId: params.idArticle,
    });

    if (resArticleList.data) setArticle(resArticleList.data);
    if (resArticleObj.data) {
      setArticleObj(resArticleObj.data[0]);
      setLoading(false);
    }
  };

  const theme = useTheme();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen768 = useMediaQuery("(min-width:768px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");

  return (
    <Grid container component="main">
      <BreadCrumbsSection libPages={libPages} articleObj={articleObj} />

      <Grid
        item
        container
        sx={{
          maxWidth: isScreen2000
            ? "1536px"
            : isScreen1700
            ? "1440px"
            : isScreen1400
            ? "1200px"
            : isScreen1200
            ? "1024px"
            : "900px",
          m: "auto",
          p: 3,
        }}
      >
        <Grid item xs={12} sm={isScreen768 ? 8 : null} md={8} lg={7}>
          {loading && (
            <>
              <Skeleton variant="rectangular" sx={{ height: "3rem" }} />
              <Skeleton
                variant="rectangular"
                sx={{ height: "0.75rem", mt: 3 }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ height: "0.75rem", mt: 1, mb: 3 }}
              />
              <Skeleton variant="rectangular" sx={{ height: "20rem", mt: 1 }} />
            </>
          )}
          {!loading && (
            <Stack
              direction="column"
              sx={{
                mr: isScreen768 ? 3 : null,
                fontFamily:
                  websiteFor === "recruitment" ? "Montserrat" : "Poppins",
                "& span": {
                  lineHeight: "24px",
                  letterSpacing: "0.02em",
                },
              }}
            >
              <Typography
                component="h2"
                variant={isScreen600 ? "h3" : "h5"}
                sx={{ fontWeight: 600 }}
              >
                {articleObj.title}
              </Typography>
              <Stack
                direction="row"
                sx={{
                  mt: 2,
                  mb: 2,
                }}
              >
                <Typography
                  variant={isScreen600 ? "body2" : "caption"}
                  sx={{ color: `${theme.palette.primary.main}` }}
                >
                  {articleObj.creator}{" "}
                  <span style={{ color: "#212121" }}>
                    - {parsedDate(articleObj.created_at, "detail-article")}
                  </span>
                </Typography>
              </Stack>
              {ReactHtmlParser(articleObj.content)}
            </Stack>
          )}
        </Grid>
        <Grid item lg={1}></Grid>
        <Grid item xs={12} sm={isScreen768 ? 4 : null} md={4} lg={4}>
          <Stack direction="column">
            <Typography
              variant={isScreen600 ? "h5" : "subtitle1"}
              sx={{
                fontWeight: 700,
                mt: isScreen768 ? 1 : 3,
                color: `${theme.palette.primary.main}`,
                fontStyle: "italic",
              }}
            >
              TAGS
            </Typography>
            <Grid container spacing={1} sx={{ mt: 1 }}>
              {articleObj.tags &&
                articleObj.tags.map((v) => (
                  <Grid item key={v.tag_id}>
                    <Chip label={v.tag_name} color="primary" />
                  </Grid>
                ))}
            </Grid>
            <Typography
              variant={isScreen600 ? "h5" : "subtitle1"}
              sx={{
                fontWeight: 700,
                mt: 3,
                color: `${theme.palette.primary.main}`,
                fontStyle: "italic",
              }}
            >
              Artikel Terkait
            </Typography>
            <Grid item container xs={12} sx={{ pt: 1 }}>
              {article.map((v, i) => {
                return (
                  <CardArt01 v={v} key={i} list="column" loading={loading} />
                );
              })}
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BreadCrumbsSection = ({ libPages, articleObj }) => {
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: "#F6F8FD",
        width: "100%",
      }}
    >
      <Grid
        item
        container
        sx={{
          maxWidth: isScreen2000
            ? "1536px"
            : isScreen1700
            ? "1440px"
            : isScreen1400
            ? "1200px"
            : isScreen1200
            ? "1024px"
            : "900px",
          m: "auto",
          p: 3,
          pt: 1,
          pb: 1,
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: 12 }}>
          <Link underline="hover" color="primary" href={`/${version}/user/`}>
            Beranda
          </Link>
          <Link
            underline="hover"
            color="primary"
            href={`/${version}/user/${libPages[2]}`}
          >
            Article
          </Link>
          <Typography variant="caption" color="text.primary">
            {articleObj.title}
          </Typography>
        </Breadcrumbs>
      </Grid>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  libPages: state.nav.libPages,
});

export default connect(mapStateToProps)(DetailArticle);
