import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import localforage from "localforage";
import { useTranslation } from "react-i18next";
import { setMiddlewareApps, setOpenLogin } from "src/redux/actions";
import { version } from "src/configs/globalVariable";
// import { Box } from "@mui/material";
// import bg from "src/assets/img/background.jpg";

const AdminLayout = ({ isActiveNavbar }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    const checkRole = async () => {
      setOpenLogin(false);
      setMiddlewareApps(false);
      const role = await localforage.getItem("userRole");
      i18n.changeLanguage("id");
      if (role !== "5") {
        navigate(`/${version}/user/`);
        window.location.reload();
      }
    };
    checkRole();
  }, []);

  return (
    <>
      <Outlet />
      {/* <Box
        sx={
          {
            // position: "relative",
            // // backgroundImage: isActiveNavbar ? null : `url(${bg})`,
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "center",
          }
        }
      >
        <Outlet />
      </Box> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  isActiveNavbar: state.nav.isActiveNavbar,
});

export default connect(mapStateToProps)(AdminLayout);
