import React, { useState, useCallback } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import Row from "./Row";
import DropZone from "../DropZone";
import { handleItemIntoParent, handleMoveWithinParent } from "../helpers";
import { connect } from "react-redux";

const Index = ({
  data,
  setData,
  rowObjHover,
  setRowObjHover,
  stateDrawer,
  setStateDrawer,
  rowObjEdit,
  setRowObjEdit,
  setOpenConfirm,
  handleUpdateData,
}) => {
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1440 = useMediaQuery("(min-width:1440px)");
  const isScreen1536 = useMediaQuery("(min-width:1536px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [dropArea, setDropArea] = useState("");
  // const [element, setElement] = useState(initialElement);

  const handleDrop = useCallback(
    (dropZone, item) => {
      // console.log("dropZone", dropZone);
      // console.log("item", item);
      setDropArea(false);

      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

      if (item.type === "item") {
        const newData = handleItemIntoParent(data, splitDropZonePath, item);
        setData(newData);
        handleUpdateData(newData);
        return; // from sidebar
      }

      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      if (splitItemPath.length === splitDropZonePath.length) {
        // MOVE WITHIN PARENT
        if (pathToItem === pathToDropZone) {
          const newData = handleMoveWithinParent(
            data,
            splitDropZonePath,
            splitItemPath
          );
          setData(newData);
          handleUpdateData(newData);
          return;
        }
      }
    },
    [data]
  );

  const renderRow = (row, currentPath, lengthMainData) => {
    return (
      <Row
        key={row.id}
        data={row}
        lengthMainData={lengthMainData}
        rowObjHover={rowObjHover}
        setRowObjHover={setRowObjHover}
        stateDrawer={stateDrawer}
        setStateDrawer={setStateDrawer}
        rowObjEdit={rowObjEdit}
        setRowObjEdit={setRowObjEdit}
        handleDrop={handleDrop}
        path={currentPath}
        dropArea={dropArea}
        setDropArea={setDropArea}
        setOpenConfirm={setOpenConfirm}
      />
    );
  };

  return (
    <Grid
      item
      container
      lg={stateDrawer.indexOf("fix") !== -1 ? 9 : 11.5}
      md={stateDrawer.indexOf("fix") !== -1 ? 9 : 11.5}
      component="section"
      sx={{ backgroundColor: "f3333" }}
      // "rgba(240,248,255,0.2)"
    >
      <Grid
        item
        xl={isScreen2000 ? 10 : 11}
        lg={11}
        sx={{
          aspectRatio: "16/9",
          width: "100%",
          m: "auto",
          height: "85vh",
          backgroundColor: "#ffff",
          border: "1px solid lightgrey",
          borderRadius: "0.75rem",
          overflowY: "auto",
          boxShadow:
            "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
        }}
        className="custom-scrollbar y box-preview"
      >
        {data.map((row, index) => {
          const currentPath = `${index}`;
          const lengthMainData= data.length

          return (
            <React.Fragment key={row.id}>
              <DropZone
                data={{
                  path: currentPath,
                  childrenCount: data.length,
                  // v: row
                }}
                lengthMainData={data.length}
                onDrop={handleDrop}
                path={currentPath}
              />
              {renderRow(row, currentPath, lengthMainData)}
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  backdrop: state.preview.backdrop,
  pagePreview: state.preview.pagePreview,
});

export default connect(mapStateToProps)(Index);
