import React, { useState, useEffect } from "react";
import { listElements } from "../../pages/admin/settingV3/library";
import {
  Stack,
  Grid,
  Typography,
  Button,
  useTheme,
  // List,
  // ListItem,
  // ListItemButton,
  // Popover,
  useMediaQuery,
  IconButton,
  styled,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  AccountCircleRounded,
  ArrowForwardIosSharp,
  Language,
  Login,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import localforage from "localforage";
import { setOpenLogin, setDeauthenticated } from "src/redux/actions";
import MenuIcon from "@mui/icons-material/Menu";
import shadows from "src/theme/shadow";
import SideDrawer from "src/components/Layout/SideDrawerV3";
import { useTranslation } from "react-i18next";
import flagInd from "src/assets/img/flagind.png";
import flagEn from "src/assets/img/flagEn.png";
import { version } from "src/configs/globalVariable";
import "src/App.css";

const NavigationBar = ({ data, isAuthenticated, pagePreview }) => {
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const theme = useTheme();
  const navigate = useNavigate();

  const [scrollTop, setScrollTop] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [isChangedMouse, setIsChangedMouse] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [bio, setBio] = useState({});
  const [linkObj, setLinkObj] = useState(false);
  const [fullname, setFullname] = useState("");
  const [userRole, setUserRole] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElGroupNav, setAnchorElGroupNav] = useState(null);
  const [anchorElLang, setAnchorElLang] = useState(null);
  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const { t, i18n } = useTranslation();
  const open = Boolean(anchorEl);
  const openGroupNav = Boolean(anchorElGroupNav);
  const openLang = Boolean(anchorElLang);
  const id = open
    ? "simple-menu"
    : openGroupNav
    ? "groupnav-menu"
    : anchorElLang
    ? "lang-menu"
    : undefined;

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setIsChanged(e.target.documentElement.scrollTop > 40);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    const resRole = await localforage.getItem("userRole");
    const resFn = await localforage.getItem("fullname");
    const resBio = await localforage.getItem("bio");
    setFullname(resFn);
    setUserRole(resRole);
    setBio(resBio);
  };

  const langs = [
    { code: "id", native: "Bahasa" },
    { code: "en", native: "English" },
  ];

  const handleTrans = (code) => {
    document.documentElement.setAttribute("lang", code);
    localStorage.setItem("lang", code);
    i18n.changeLanguage(code);
  };

  const checkPageAsideLanding = () => {
    const loc = window.location.pathname;
    let path = loc.split("/");
    if (
      path[2] === "register" ||
      path[4] === "detail" ||
      (path[3] && (path[3] === "dashboard" || (!path[4] && path[3] !== "FAQ")))
    )
      return true;
    else if (path[2] === "user" && (isChanged || isChangedMouse)) return true;
    else return false;
  };

  const navFilter = (link, e) => {
    if (anchorElGroupNav) {
      if (userRole !== "5")
        navigate(`/${version}/user/${link.text}/${linkObj.idGroup}/${link.id}`);
    } else if (link.isGroup) {
      if (link.children?.length > 0) {
        if (!isScreen900 && isExpanded) {
          setIsExpanded(false);
          setLinkObj(false);
        } else {
          setIsExpanded(link.idGroup);
          setLinkObj(link);
        }
        isScreen900 && setAnchorElGroupNav(e.currentTarget);
      } else {
        if (userRole !== "5")
          navigate(`/${version}/user/${link.text}/${link.idGroup}`);
      }
    } else {
      if (userRole !== "5") {
        if (link.url && link.url.indexOf("user") !== -1) navigate(link.url);
        else {
          if (link.url) {
            const needHttp = link.url.indexOf("http") === -1 ? "https://" : "";
            window.location.replace(`${needHttp}${link.url}`);
          }
        }
      }
    }
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await setDeauthenticated();
    setOpenSideDrawer(false);
    window.location.reload();
  };

  const enabledDisplay = () => {
    if (!isScreen900 && userRole !== "5") return true;
    else return false;
  };

  return (
    <Grid
      item
      container
      component="header"
      className={data.elCn && `el ${data.elCn}`}
      sx={{
        transition: "all linear .2s",
        position:
          window.location.pathname.indexOf("user") !== -1 ||
          window.location.pathname.indexOf("register") !== -1
            ? "fixed"
            : "unset",
        top: 0,
        backgroundColor: checkPageAsideLanding() ? "#ffff" : "transparent",
        color: checkPageAsideLanding()
          ? `${theme.palette.text.primary}`
          : "#ffff",
        borderBottom: "1.5px solid rgba(255,255,255,0.35)",
        "&:hover": { cursor: "pointer" },
        boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
        zIndex: 99,
      }}
      onMouseEnter={() => setIsChangedMouse(true)}
      onMouseLeave={() => setIsChangedMouse(false)}
    >
      {data.elCn === "triangle-header" && (
        <>
          {data.children.map((v, i) => (
            <React.Fragment key={i}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  width: "100%",
                  px: 3,
                  color: "inherit",
                  maxWidth: isScreen1700 ? "1700px" : null,
                  m: "auto",
                }}
              >
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={1}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {!enabledDisplay() &&
                    v.link
                      .filter((link) => link.text && link.visibility)
                      .map((link, x) => (
                        <Typography
                          component="a"
                          variant="caption"
                          color="inherit"
                          key={x}
                          sx={{
                            m: 2,
                            fontWeight: "bold",
                            transition: "all linear .2s",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover": {
                              cursor: "pointer",
                              transform: "scale(1.25)",
                            },
                          }}
                          onClick={(e) => navFilter(link, e)}
                        >
                          {/* {link.text} */}
                          {i18n.language === "en" ? link.textEn : link.text}
                          {link.children?.length > 0 && (
                            <ArrowForwardIosSharp
                              sx={{
                                ml: 1.25,
                                width: "0.75rem",
                                transition: "all linear .2s",
                                transform:
                                  isExpanded === link.idGroup
                                    ? "rotate(90deg)"
                                    : "rotate(0deg)",
                              }}
                            />
                          )}
                        </Typography>
                      ))}

                  {enabledDisplay() && (
                    <IconButton
                      size="large"
                      sx={{
                        color: checkPageAsideLanding()
                          ? `${theme.palette.text.primary}`
                          : "#ffff",
                      }}
                      onClick={() => setOpenSideDrawer(true)}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={10}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {listElements(v)}
                </Grid>
                {!enabledDisplay() && (
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {/* <Button
                      variant="outlined"
                      sx={{
                        // mr: 2,
                        border: "none",
                        color: checkPageAsideLanding()
                          ? `${theme.palette.text.primary}`
                          : "#ffff",
                        transition: "all linear .2s",
                        "&:hover": {
                          borderRadius: "5rem",
                          boxShadow: `${shadows[8]}`,
                          border: "none",
                        },
                      }}
                      startIcon={
                        i18n.language === "en" ? (
                          <img
                            alt="flag"
                            src={flagEn}
                            style={{ width: "1.5rem", height: "1.5rem" }}
                          />
                        ) : (
                          <img
                            alt="flag"
                            src={flagInd}
                            style={{ width: "2rem", height: "1.5rem" }}
                          />
                        )
                      }
                      onClick={(e) =>
                        userRole !== "5"
                          ? setAnchorElLang(e.currentTarget)
                          : null
                      }
                    >
                      {t(`Bahasa`)}
                    </Button> */}

                    {(userRole === "5" || !isAuthenticated) && (
                      <Button
                        variant="outlined"
                        sx={{
                          border: "none",
                          color: checkPageAsideLanding()
                            ? `${theme.palette.text.primary}`
                            : "#ffff",
                          transition: "all linear .2s",
                          "&:hover": {
                            borderRadius: "5rem",
                            boxShadow: `${shadows[8]}`,
                            border: "none",
                          },
                        }}
                        startIcon={<Login />}
                        onClick={
                          window.location.pathname.indexOf("user") !== -1
                            ? () => setOpenLogin(true)
                            : null
                        }
                      >
                        {t(`login`)}
                      </Button>
                    )}
                    {userRole !== "5" && isAuthenticated && (
                      <Button
                        variant="outlined"
                        startIcon={<AccountCircleRounded />}
                        sx={{
                          border: "none",
                          transition: "all linear .2s",
                          color: checkPageAsideLanding()
                            ? `${theme.palette.text.primary}`
                            : "#ffff",
                          "&:hover": {
                            borderRadius: "5rem",
                            boxShadow: `${shadows[8]}`,
                            border: "none",
                          },
                        }}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                      >
                        {fullname}
                      </Button>
                    )}
                  </Grid>
                )}
              </Stack>
              {!enabledDisplay() && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: "100%",
                    color: "inherit",
                    maxWidth: isScreen1700 ? "1700px" : null,
                    m: isScreen1700 && "auto",
                  }}
                >
                  {v.mainLink
                    .filter((link) => link.text && link.visibility)
                    .map((link, x) => (
                      <Typography
                        component="a"
                        variant="body1"
                        key={x}
                        sx={{
                          m: 2,
                          mx: 3,
                          mt: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transition: "all linear .2s",
                          "&:hover": {
                            cursor: "pointer",
                            transform: "scale(1.25)",
                          },
                        }}
                        onClick={(e) => navFilter(link, e)}
                      >
                        {/* {link.text} */}
                        {i18n.language === "en" ? link.textEn : link.text}
                        {link.children?.length > 0 && (
                          <ArrowForwardIosSharp
                            sx={{
                              ml: 1.25,
                              width: "0.75rem",
                              transition: "all linear .2s",
                              transform:
                                isExpanded === link.idGroup
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)",
                            }}
                          />
                        )}
                      </Typography>
                    ))}
                </Stack>
              )}
            </React.Fragment>
          ))}
        </>
      )}

      <StyledMenu
        id={id}
        anchorEl={anchorElGroupNav}
        open={openGroupNav}
        groupnav="true"
        onClose={() => {
          setIsExpanded(false);
          setAnchorElGroupNav(null);
        }}
      >
        {linkObj &&
          linkObj.children
            ?.filter((link) => link.text && link.visibility)
            .map((nav, i) => (
              <MenuItem key={i} onClick={() => navFilter(nav)}>
                {i18n.language === "en" ? nav?.textEn : nav?.text}
              </MenuItem>
            ))}
      </StyledMenu>

      <StyledMenu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {data.children[0]?.dashboard.map((nav, i) => (
          <MenuItem key={i} onClick={() => navFilter(nav)}>
            {i18n.language === "en" ? nav?.textEn : nav?.text}
          </MenuItem>
        ))}
        <MenuItem onClick={() => handleLogout()}>{t(`logout`)}</MenuItem>
      </StyledMenu>

      <StyledMenu
        id={id}
        anchorEl={anchorElLang}
        open={openLang}
        onClose={() => setAnchorElLang(null)}
      >
        {langs.map((lang, i) => {
          const { code, native } = lang;
          return (
            <MenuItem onClick={() => handleTrans(code)}>{native}</MenuItem>
          );
        })}
      </StyledMenu>

      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          {data.children[0]?.dashboard.map((nav, i) => (
            <ListItem disablePadding key={i}>
              <ListItemButton onClick={() => navFilter(nav?.url)}>
                <Typography variant="body2">{nav?.text}</Typography>
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLogout()}>
              <Typography variant="body2">Logout</Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover> */}

      {/* Mobile nav */}
      <SideDrawer
        data={data}
        openSideDrawer={openSideDrawer}
        setOpenSideDrawer={setOpenSideDrawer}
        navFilter={navFilter}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        linkObj={linkObj}
        isAuthenticated={isAuthenticated}
        setAnchorEl={setAnchorEl}
        fullname={fullname}
      />
    </Grid>
  );
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: props.groupnav ? "center" : "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: props.groupnav ? "center" : "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 3,
    marginTop: theme.spacing(2),
    minWidth: 180,
    color: `${theme.palette.text.primary}`,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
  },
}));

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  pagePreview: state.preview.pagePreview,
  lang: localStorage.getItem("lang"),
});
export default connect(mapStateToProps)(NavigationBar);
