import {
  grey,
  red,
  pink,
  purple,
  indigo,
  blue,
  teal,
  yellow,
  deepOrange,
} from "./colors";

const getDesignTokens = (str, str2, font, isPreview) => ({
  palette: {
    type: "light",
    text: {
      primary: grey[900],
      secondary: grey[300],
      tertiary: grey[500],
    },
    black: { main: "#212121" },
    blue: { main: "#0000ff" },
    white: { main: "#fffff" },
    red: { main: "#D32F2F" },

    ...(str === "red"
      ? {
          primary: { main: red[500] },
          primaryButton: {
            main: `linear-gradient(to bottom right, ${red[500]} 0%, ${red[200]} ) 100%`,
          },
          background: {
            paper: `linear-gradient(to bottom, ${red[50]}, rgb(255,255,255), ${red[50]})`,
            paper2: `linear-gradient(to right, rgb(255,255,255), ${red[100]}, rgb(255,255,255))`,
            paper3: `linear-gradient(to bottom, rgba(255,255,255, 0), ${red[300]})`,
            paper4: `${red[50]}`,
          },
        }
      : str === "pink"
      ? {
          primary: { main: pink[500] },
          primaryButton: {
            main: `linear-gradient(to bottom right, ${pink[500]} 0%, ${pink[200]} ) 100%`,
          },
          background: {
            paper: `linear-gradient(to bottom, ${pink[50]}, rgb(255,255,255), ${pink[50]})`,
            paper2: `linear-gradient(to right, rgb(255,255,255), ${pink[100]}, rgb(255,255,255))`,
            paper3: `linear-gradient(to bottom, rgba(255,255,255, 0), ${pink[300]})`,
            paper4: `${pink[50]}`,
          },
        }
      : str === "purple"
      ? {
          primary: { main: purple[500] },
          primaryButton: {
            main: `linear-gradient(to bottom right, ${purple[500]} 0%, ${purple[200]} ) 100%`,
          },
          background: {
            paper: `linear-gradient(to bottom, ${purple[50]}, rgb(255,255,255), ${purple[50]})`,
            paper2: `linear-gradient(to right, rgb(255,255,255), ${purple[100]}, rgb(255,255,255))`,
            paper3: `linear-gradient(to bottom, rgba(255,255,255, 0), ${purple[300]})`,
            paper4: `${purple[50]}`,
          },
        }
      : str === "indigo"
      ? {
          primary: { main: indigo[500] },
          primaryButton: {
            main: `linear-gradient(to bottom right, ${indigo[500]} 0%, ${indigo[200]} ) 100%`,
          },
          background: {
            paper: `linear-gradient(to bottom, ${indigo[50]}, rgb(255,255,255), ${indigo[50]})`,
            paper2: `linear-gradient(to right, rgb(255,255,255), ${indigo[100]}, rgb(255,255,255))`,
            paper3: `linear-gradient(to bottom, rgba(255,255,255, 0), ${indigo[300]})`,
            paper4: `${indigo[50]}`,
          },
        }
      : str === "blue"
      ? {
          primary: { main: blue[500] },
          primaryButton: {
            main: `linear-gradient(to bottom right, ${blue[500]} 0%, ${blue[200]} ) 100%`,
          },
          background: {
            paper: `linear-gradient(to bottom, ${blue[50]}, rgb(255,255,255), ${blue[50]})`,
            paper2: `linear-gradient(to right, rgb(255,255,255), ${blue[100]}, rgb(255,255,255))`,
            paper3: `linear-gradient(to bottom, rgba(255,255,255, 0), ${blue[300]})`,
            paper4: `${blue[50]}`,
          },
        }
      : str === "teal"
      ? {
          primary: { main: teal[500] },
          primaryButton: {
            main: `linear-gradient(to bottom right, ${teal[500]} 0%, ${teal[200]} ) 100%`,
          },
          background: {
            paper: `linear-gradient(to bottom, ${teal[50]}, rgb(255,255,255) , ${teal[50]})`,
            paper2: `linear-gradient(to right, rgb(255,255,255), ${teal[100]}, rgb(255,255,255))`,
            paper3: `linear-gradient(to bottom, rgba(255,255,255, 0), ${teal[300]})`,
            paper4: `${teal[50]}`,
          },
        }
      : str === "yellow"
      ? {
          primary: { main: yellow[500] },
          primaryButton: {
            main: `linear-gradient(to bottom right, ${yellow[500]} 0%, ${yellow[200]} ) 100%`,
          },
          background: {
            paper: `linear-gradient(to bottom, ${yellow[50]}, rgb(255,255,255), ${yellow[50]})`,
            paper2: `linear-gradient(to right, rgb(255,255,255), ${yellow[100]}, rgb(255,255,255))`,
            paper3: `linear-gradient(to bottom, rgba(255,255,255, 0), ${yellow[300]})`,
            paper4: `${yellow[50]}`,
          },
        }
      : str === "deepOrange"
      ? {
          primary: { main: deepOrange[500] },
          primaryButton: {
            main: `linear-gradient(to bottom right, ${deepOrange[500]} 0%, ${deepOrange[200]} ) 100%`,
          },
          background: {
            paper: `linear-gradient(to bottom, ${deepOrange[50]}, rgb(255,255,255), ${deepOrange[50]})`,
            paper2: `linear-gradient(to right, rgb(255,255,255), ${deepOrange[100]}, rgb(255,255,255))`,
            paper3: `linear-gradient(to bottom, rgba(255,255,255, 0), ${deepOrange[300]})`,
            paper4: `${deepOrange[50]}`,
          },
        }
      : {
          primary: { main: "#ffff" },
          primaryButton: { main: "#ffff" },
          background: { paper: "#ffff", paper2: "#ffff" },
        }),

    ...(str2 === "red"
      ? {
          secondary: { main: red[500] },
          secondaryButton: {
            main: `linear-gradient(to bottom right, ${red[500]} 0%, ${red[200]} ) 100%`,
          },
        }
      : str2 === "pink"
      ? {
          secondary: { main: pink[500] },
          secondaryButton: {
            main: `linear-gradient(to bottom right, ${pink[500]} 0%, ${pink[200]} ) 100%`,
          },
        }
      : str2 === "purple"
      ? {
          secondary: { main: purple[500] },
          secondaryButton: {
            main: `linear-gradient(to bottom right, ${purple[500]} 0%, ${purple[200]} ) 100%`,
          },
        }
      : str2 === "indigo"
      ? {
          secondary: { main: indigo[500] },
          secondaryButton: {
            main: `linear-gradient(to bottom right, ${indigo[500]} 0%, ${indigo[200]} ) 100%`,
          },
        }
      : str2 === "blue"
      ? {
          secondary: { main: blue[500] },
          secondaryButton: {
            main: `linear-gradient(to bottom right, ${blue[500]} 0%, ${blue[200]} ) 100%`,
          },
        }
      : str2 === "teal"
      ? {
          secondary: { main: teal[500] },
          secondaryButton: {
            main: `linear-gradient(to bottom right, ${teal[500]} 0%, ${teal[200]} ) 100%`,
          },
        }
      : str2 === "yellow"
      ? {
          secondary: { main: yellow[500] },
          secondaryButton: {
            main: `linear-gradient(to bottom right, ${yellow[500]} 0%, ${yellow[200]} ) 100%`,
          },
        }
      : str2 === "deepOrange"
      ? {
          secondary: { main: deepOrange[500] },
          secondaryButton: {
            main: `linear-gradient(to bottom right, ${deepOrange[500]} 0%, ${deepOrange[200]} ) 100%`,
          },
        }
      : {
          secondary: { main: "#fff" },
          secondaryButton: { main: "#ffff" },
        }),
  },
  typography: {
    fontFamily: font,
    h1: {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: "56px",
      letterSpacing: "0.02em",
    },
    h2: {
      fontSize: 38,
      fontWeight: 700,
      lineHeight: "57px",
      letterSpacing: "0.02em",
    },
    h3: {
      fontSize: 28,
      fontWeight: 700,
      lineHeight: "42px",
      letterSpacing: "0.02em",
    },
    h4: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "36px",
      letterSpacing: "0.02em",
    },
    h5: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: "30px",
      letterSpacing: "0.02em",
    },
    subtitle1: {
      fontSize: 18,
      lineHeight: "27px",
      letterSpacing: "0.02em",
    },
    body1: {
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: "0.02em",
    },
    body2: {
      fontSize: 14,
      lineHeight: "21px",
      letterSpacing: "0.02em",
    },
    caption: {
      fontSize: 12,
      lineHeight: "18px",
      letterSpacing: "0.02em",
    },
    caption2: {
      fontSize: 10,
      lineHeight: "12px",
      letterSpacing: "0.02em",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
        },
        sizeSmall: {
          height: "27px",
          padding: "auto 12px",
          fontSize: 10,
          lineHeight: "12px",
          letterSpacing: "0.02em",
        },
        sizeNormal: {
          height: "36px",
          padding: "0 12px",
          fontSize: 14,
          lineHeight: "16px",
          letterSpacing: "0.02em",
        },
        sizeMedium: {
          height: "40px",
          padding: "0 24px",
          fontSize: 14,
          lineHeight: "21px",
          letterSpacing: "0.02em",
        },
        sizeLarge: {
          height: "56px",
          padding: "0 60px",
          fontSize: 18,
          lineHeight: 1.4,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "&$focused": {
              ml: 0,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "5rem",
          backgroundColor: "#ffff",
          fontSize: isPreview ? 8 : 14,
          height: isPreview ? "27px" : "40px",

          input: {
            "&::placeholder": {
              fontSize: isPreview ? 10 : 14,
            },
          },
        },
        multiline: {
          height: "auto !important",
          fontSize: 14,
          borderRadius: "0.75rem",
        },
      },
    },
    MuiPickersModal: {
      styleOverrides: {
        dialogAction: {
          backgroundColor: "#ffff",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: 14,
          display: "flex",
          alignItems: "center",
          borderRadius: "0.25rem",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: "#ffff",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: "#ffff",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: isPreview ? "12px" : "25px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#adb5bd",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        },
      },
    },
  },
});

export default getDesignTokens;
