import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        scrollBehavior: "smooth",
        transition: "all .2s linear",
        height: "100%",
        width: "100%",
      },
      body: {
        backgroundColor: "#F6F8FD",
        // backgroundColor: "#f4f6f8",
        // backgroundColor: "#F0f0ff",

        // backgroundColor: "#F1F6F5",
        // backgroundColor: "rgba(226, 62, 87, 0.1)" ,
        height: "100%",
        width: "100%",
      },
      a: {
        textDecoration: "none",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      ".dnd-image": {
        minHeight: "150px !important",
        height: "150px !important",
      },

      ".dnd-image > div > svg": {
        marginBottom: "2rem",
        marginTop: "-1rem",
      },

      ".dnd-file": {
        minHeight: "50px !important",
        height: "50px !important",
      },

      ".dnd-file > div > svg": {
        display: "none !important",
      },

      ".dnd-file > div > p": {
        marginTop: "12px",
        textAlign: "left",
        marginLeft: "16px",
        fontSize: "1rem",
      },

      ".MuiCalendarPicker-root": {
        backgroundColor: "#FFF !important",
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
