import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Stack } from "@mui/material";
import UserRow from "src/components/Layout/UserRow";
import localforage from "localforage";
import "src/App.css";

const Index = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState("container");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const resFAQ = await localforage.getItem("faq");
    setData(resFAQ);
    setLoading(false);
  };

  const renderRow = (row) => {
    return <UserRow key={row.id} data={row} />;
  };

  if (loading === "container")
    return (
      <Stack>
        <CircularProgress sx={{ m: "auto" }} />
      </Stack>
    );

  return (
    <Grid
      component="main"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {data
        // .filter((v) => v.visibility)
        .map((row) => {
          return <React.Fragment key={row.id}>{renderRow(row)}</React.Fragment>;
        })}
    </Grid>
  );
};

export default Index;
