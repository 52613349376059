import React, { useState, useEffect } from "react";
import { listElements } from "../../pages/admin/settingV3/library";
import { Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import localforage from "localforage";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "src/App.css";

const SimpleScrollItem = ({ data }) => {
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const navigate = useNavigate();
  const [bio, setBio] = useState({});
  const { i18n } = useTranslation();

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    const resBio = await localforage.getItem("bio");
    setBio(resBio);
  };

  const navFilter = (url) => {
    if (url && url.indexOf("user") !== -1) navigate(url);
    else {
      if (url) {
        const needHttp = url.indexOf("http") === -1 ? "https://" : "";
        window.location.replace(`${needHttp}${url}`);
      }
    }
    // if (url.indexOf(bio.domain) !== -1) {
    //   let link;
    //   if (bio.domain === "localhost") link = url.split(bio.domain + ":3000");
    //   else link = url.split(bio.domain);
    //   navigate(link[1]);
    // } else {
    //   window.location.replace(data.url);
    // }
  };

  return (
    <Stack direction="row" sx={{ mx: isScreen900 ? "0.45rem" : "0.35rem" }}>
      {data.children.map((card, index) => {
        return (
          <Paper
            key={index}
            elevation={2}
            sx={{
              my: isScreen900 ? "0.75rem" : "0.45rem",
              mx: isScreen900 ? "0.75rem" : "0.45rem",
              position: "relative",
              scrollSnapAlign: "end",
              borderRadius: "0.75rem",
              transition: "all linear .2s",
              "&:hover": {
                cursor: "pointer",
                transform: "scale(1.05)",
              },
              // ...card.cardProps,

              height: !isScreen900 ? "15rem" : "20rem",
              width: !isScreen900 ? "10.5rem" : "13rem",
            }}
            onClick={() => navFilter(card.url)}
          >
            {listElements(card)}
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
              sx={{
                borderRadius: "0.75rem",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.25)",
              }}
            >
              <Typography
                variant={isScreen900 ? "body1" : "body2"}
                component="p"
                sx={{
                  fontWeight: "bold",
                  color: "#ffff",
                  mb: isScreen900 ? "2rem" : "0.5rem",
                  p: 1,
                  textAlign: "center",
                }}
              >
                {i18n.language === "en" ? card.textEn : card.text}
                {/* {card.text} */}
              </Typography>
            </Stack>
          </Paper>
        );
      })}
    </Stack>
  );
};
export default SimpleScrollItem;
