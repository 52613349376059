import React, { useState, useEffect } from "react";
import localforage from "localforage";
import { Grid } from "@mui/material";
import UserRow from "src/components/Layout/UserRow";
import { useNavigate } from "react-router";
import { version } from "src/configs/globalVariable";

const Index = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const resData = await localforage.getItem("data");
    setData(resData);
    if (localStorage.getItem("invited")) {
      navigate(`/${version}/user/profile`);
    }
  };

  const renderRow = (row) => {
    return <UserRow key={row.id} data={row} />;
  };

  return (
    <Grid component="main" sx={{}}>
      {data
        .filter((v) => v.visibility)
        .map((row) => {
          return <React.Fragment key={row.id}>{renderRow(row)}</React.Fragment>;
        })}
    </Grid>
  );
};
export default Index;
