import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Drawer from "./Drawer";
import SlideDrawer from "./SlideDrawer";
import FixedDrawer from "./FixedDrawer";
import "../../../../App.css";

const Index = ({
  stateDrawer,
  setStateDrawer,
  data,
  setData,
  rowObjEdit,
  setRowObjEdit,
  openConfirm,
  setOpenConfirm,
  loadingDeleteNav,
  setLoadingDeleteNav,
  handleVisibility,
  handleChangePage,
}) => {
  useEffect(() => {
    // setStateDrawer("fixCom");
  }, []);

  return (
    <>
      <Grid
        item
        container
        lg={0.5}
        md={0.5}
        component="section"
        sx={{
          backgroundColor: "#ffff", /// 293039
          color: "#ffff",
          overflowY: "auto",
          boxShadow:
            "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
        }}
      >
        <Drawer
          stateDrawer={stateDrawer}
          setStateDrawer={setStateDrawer}
          setRowObjEdit={setRowObjEdit}
        />
        {stateDrawer.indexOf("fix") === -1 && (
          <SlideDrawer
            stateDrawer={stateDrawer}
            setStateDrawer={setStateDrawer}
            data={data}
            setData={setData}
            rowObjEdit={rowObjEdit}
            setRowObjEdit={setRowObjEdit}
            openConfirm={openConfirm}
            setOpenConfirm={setOpenConfirm}
            loadingDeleteNav={loadingDeleteNav}
            setLoadingDeleteNav={setLoadingDeleteNav}
            handleVisibility={handleVisibility}
            handleChangePage={handleChangePage}
          />
        )}
      </Grid>

      {stateDrawer.indexOf("fix") !== -1 && (
        <FixedDrawer
          stateDrawer={stateDrawer}
          setStateDrawer={setStateDrawer}
          setRowObjEdit={setRowObjEdit}
        />
      )}
    </>
  );
};
export default Index;
