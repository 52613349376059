import React, { useEffect, useState } from "react";
import { Typography, Button, CircularProgress } from "@mui/material";
import { getJobs, setSnackbarOpen } from "src/redux/actions";
import localforage from "localforage";
import { domain } from "src/components/Layout/dummy";
import { parsedDate } from "src/functions/parsedDate";
import { useNavigate } from "react-router";
import TableMaterial from "./TableMaterial";
import { version } from "src/configs/globalVariable";

const Vacancy = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [font, setFont] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const resTheme = await localforage.getItem("theme");
    if (resTheme.websiteFor === "recruitment") {
      const resJobs = await getJobs({ domain });
      // console.log(resJobs.data);
      setData(resJobs.data);
    }
    setFont(resTheme.font);
    setLoading(false);
  };

  if (loading) return <CircularProgress />;

  const styleCell = { fontFamily: font, fontSize: "14px" };

  return (
    <>
      <TableMaterial
        title=""
        columns={[
          {
            title: "Nama Posisi",
            field: "ref_position_group.position_group_name",
          },
          {
            title: "Tipe Rekrutmen",
            field: "ref_hiring_type.hiring_type_name",
            cellStyle: { width: "20%", ...styleCell },
          },
          {
            title: "Jenjang Pendidikan",
            // field: "ref_hiring_type.hiring_type_name",
            headerStyle: { textAlign: "center" },
            cellStyle: {
              width: "10%",
              textALign: "center",
              ...styleCell,
            },
            render: (d) => {
              return (
                <Typography variant="body2">
                  {d.educational_codes
                    ?.map((ed) => ed.educational_name)
                    ?.join(", ")}
                </Typography>
              );
            },
          },
          {
            title: "Jurusan",
            // field: "ref_hiring_type.hiring_type_name",
            cellStyle: { textALign: "center", ...styleCell },
            render: (d) => {
              return (
                <Typography variant="body2">
                  {d.major?.map((mj) => mj.major_field_name)?.join(", ")}
                </Typography>
              );
            },
          },
          {
            title: "Closed Date",
            headerStyle: { textAlign: "center" },
            cellStyle: { textALign: "center" },
            render: (d) => {
              return (
                <Typography variant="body2" sx={{ textAlign: "center" }}>
                  {parsedDate(d.closed_at, "article")}
                </Typography>
              );
            },
          },
          {
            title: "",
            render: (d) => {
              return (
                <Button
                  variant="contained"
                  sx={{ borderRadius: "5rem" }}
                  onClick={() =>
                    navigate(`/${version}/user/vacancy/detail`, { state: d })
                  }
                >
                  Lihat Detail
                </Button>
              );
            },
          },
        ]}
        data={data}
        pageSize={10}
        selection="true"
        isLoading={loading}
        font={font}
      />
    </>
  );
};
export default Vacancy;
