export function parsedDate(str, name) {
  let date = new Date(str);

  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  let hour = date.getHours();
  let min = date.getMinutes();

  date =
    name === "detail-article"
      ? dd + " " + listMonthFull(mm) + " " + yyyy + `, ${hour}:${min} WIB`
      : name === "article"
      ? dd + " " + listMonthFull(mm) + " " + yyyy
      : dd + " " + listMonth(mm) + " " + yyyy;
  return date.toString();
}

function listMonth(month) {
  switch (month) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "Aug";
    case 9:
      return "Sept";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
    default:
      break;
  }
}

function listMonthFull(month) {
  switch (month) {
    case 1:
      return "Januari";
    case 2:
      return "Februari";
    case 3:
      return "Maret";
    case 4:
      return "April";
    case 5:
      return "Mei";
    case 6:
      return "Juni";
    case 7:
      return "Juli";
    case 8:
      return "Agustus";
    case 9:
      return "September";
    case 10:
      return "Oktober";
    case 11:
      return "November";
    case 12:
      return "Desember";
    default:
      break;
  }
}
