import React from "react";
import { IconButton, Stack } from "@mui/material";
import { setOpenLogin, setDeauthenticated } from "src/redux/actions";
import "../../../../App.css";
import { Logout, Palette, Web, Widgets } from "@mui/icons-material";

const Drawer = ({ stateDrawer, setStateDrawer, setRowObjEdit }) => {
  const handleLogout = async () => {
    await setDeauthenticated();
    window.location.reload();
  };
  return (
    <Stack flexDirection="column" sx={{ width: "100%" }}>
      <IconButton
        sx={{
          mx: "auto",
          my: 2,
          p: 2,
          mb: 1,
          width: "40px",
          height: "40px",
          boxShadow:
            stateDrawer === "nav" &&
            `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
          backgroundColor: stateDrawer === "nav" && "#f0f8ff",

          "&:hover": {
            boxShadow: `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
            backgroundColor: "#f0f8ff",
            cursor: "pointer",
          },
        }}
        onClick={() => {
          setRowObjEdit({});
          setStateDrawer("");
          setTimeout(() => {
            setStateDrawer("nav");
          }, 50);
        }}
      >
        {/* hover tooltips */}
        {/* <i className="fi fi-sr-apps-add"></i> */}
        <Widgets />
      </IconButton>
      <IconButton
        sx={{
          mx: "auto",
          my: 2,
          p: 2,
          mt: 1,
          width: "40px",
          height: "40px",
          boxShadow:
            stateDrawer === "fixCom" &&
            `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
          backgroundColor: stateDrawer === "fixCom" && "#f0f8ff",

          "&:hover": {
            boxShadow: `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
            backgroundColor: "#f0f8ff",
            cursor: "pointer",
          },
        }}
        onClick={() => {
          setRowObjEdit({});
          setStateDrawer("");
          setStateDrawer("fixCom");
        }}
      >
        {/* <i class="fi fi-sr-layout-fluid"></i> */}
        <Web />
      </IconButton>
      <IconButton
        sx={{
          mx: "auto",
          my: 2,
          p: 2,
          mt: 1,
          width: "40px",
          height: "40px",
          boxShadow:
            stateDrawer === "fixTheme" &&
            `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
          backgroundColor: stateDrawer === "fixTheme" && "#f0f8ff",

          "&:hover": {
            boxShadow: `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
            backgroundColor: "#f0f8ff",
            cursor: "pointer",
          },
        }}
        onClick={() => {
          setRowObjEdit({});
          setStateDrawer("");
          setStateDrawer("fixTheme");
        }}
      >
        {/* <i class="fi fi-sr-palette"></i> */}
        <Palette />
      </IconButton>
      <IconButton
        sx={{
          mx: "auto",
          my: 4,
          p: 2,
          mt: 1,
          width: "40px",
          height: "40px",

          "&:hover": {
            boxShadow: `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
            backgroundColor: "#f0f8ff",
            cursor: "pointer",
          },
        }}
        onClick={() => handleLogout()}
      >
        {/* <i class="fi fi-br-sign-out-alt"></i> */}
        <Logout />
      </IconButton>
    </Stack>
  );
};
export default Drawer;
