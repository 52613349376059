import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  AppBar,
  useTheme,
  Button,
  useMediaQuery,
  List,
  ListItem,
  ListItemButton,
  Popover,
  IconButton,
  Box,
} from "@mui/material";
import { AccountCircleRounded } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  setOpenLogin,
  setIsActiveNavbar,
  setDeauthenticated,
} from "src/redux/actions";
import SideDrawer from "./SideDrawer";
import shadows from "src/theme/shadow";
import { connect } from "react-redux";
import localforage from "localforage";
import "src/App.css";
import LoadableImage from "./LoadableImage";
import { version } from "src/configs/globalVariable";

const Header = ({
  isAuthenticated,
  isActiveNavbar,
  navPreview,
  logoPreview,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1024 = useMediaQuery("(min-width:1024px)");
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const [fullname, setFullname] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [logo, setLogo] = useState("");
  const [userRole, setUserRole] = useState("");
  const [getAppSetting, setAppSetting] = useState({});
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [menu, setMenu] = useState([]);
  const [dashboardMenu, setDashboardMenu] = useState({});
  const [openSideDrawer, setOpenSideDrawer] = useState(false);

  useEffect(() => {
    fetchInfo();
  }, [navPreview]);

  const fetchInfo = async () => {
    const resRole = await localforage.getItem("userRole");
    const resNav = navPreview.length
      ? navPreview
      : await localforage.getItem("navigation");
    const resBio = await localforage.getItem("bio");
    const resFn = await localforage.getItem("fullname");
    const resTheme = await localforage.getItem("theme");

    setUserRole(resRole);
    setLogo(logoPreview ? logoPreview : resBio.logo);
    setFullname(resFn);
    setAppSetting(resTheme);
    /* navigation */
    const arr = [];
    resNav.map((v) => {
      if (v.page === "dashboard") {
        setDashboardMenu(v);
        arr.push(v);
      } else arr.push(v);
      return v;
    });
    setMenu(arr);
  };

  const handleMenu = (v) => {
    setIsActiveNavbar(v.title);
    setOpenSideDrawer(false);
    navigate(v.link);
  };

  const handleClick = (v) => {
    setAnchorEl(null);
    setOpenSideDrawer(false);
    navigate(v.link);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await setDeauthenticated();
    setOpenSideDrawer(false);
    window.location.reload();
  };

  const handleMaxWidthNav = () => {
    if (userRole === "5") {
      return "500px";
    } else {
      if (isScreen2000) return "1536px";
      else if (isScreen1700) return "1440px";
      else if (isScreen1400) return "1200px";
      else if (isScreen1200) return "1024px";
      else return "900px"; // 1024px
    }
  };
  return (
    <AppBar
      sx={{
        position: "sticky",
        backgroundColor:
          getAppSetting.websiteFor === "recruitment"
            ? "rgb(255,255,255)"
            : "rgb(255,255,255)", // `${theme.palette.background.paper3}` // transparent
        borderTopLeftRadius: userRole === "5" ? "0.5rem" : null,
        boxShadow:
          // getAppSetting.websiteFor === "recruitment"
          // ?
          "rgba(0, 0, 0, 0.09) 0px 3px 12px",
        // : "none",
      }}
      id="header"
    >
      <Grid
        container
        sx={{
          maxWidth: handleMaxWidthNav(),
          m: "auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          pl: userRole === "5" || isScreen1024 ? null : isScreen600 ? 3 : 2,
          pr: userRole === "5" || isScreen1024 ? null : isScreen600 ? 3 : 2,
        }}
      >
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <LoadableImage
            src={logoPreview ? logoPreview : logo}
            alt="logo"
            style={{
              height: userRole === "5" ? "2.25rem" : "3.5rem",
              // isScreen1024 ? "3.5rem" :
              opacity: logo ? "1" : "0",
              paddingTop: "0.05rem",
              paddingBottom: "0.05rem",
            }}
            className="logo"
            onClick={
              userRole === "5" ? null : () => navigate(`/${version}/user/`)
            }
          />
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          {isScreen1024 && (
            <Box
              component="nav"
              sx={{
                display: "flex",
                m: userRole === "5" ? "auto 0.75rem" : "auto 1rem",
                alignItems: "center",
              }}
            >
              <ul>
                {menu
                  .filter((x) => x.title)
                  .map((v, i) => (
                    <li key={v.id}>
                      <Typography
                        component="a"
                        variant={userRole === "5" ? "caption2" : "body2"}
                        sx={{
                          color:
                            v.title === isActiveNavbar
                              ? `${theme.palette.text.primary}`
                              : `${theme.palette.text.secondary}`,

                          fontWeight: 700,
                          transition: "all linear .4s",
                          p: userRole === "5" ? 0 : 1,

                          display: "flex",
                          alignItems: "center",

                          ml: userRole === "5" ? "0.75rem" : "1rem",
                          mr: userRole === "5" ? "0.75rem" : "1rem",

                          "&:hover": {
                            color: `${theme.palette.text.primary}`,
                            textDecoration: "none",
                            fontWeight: 700,
                            cursor: "pointer",
                          },
                        }}
                        onClick={
                          userRole === "5"
                            ? null
                            : v.page === "dashboard"
                            ? () => handleClick(dashboardMenu)
                            : () => handleMenu(v)
                        }
                      >
                        {v.title}
                      </Typography>
                    </li>
                  ))}
              </ul>
            </Box>
          )}
          {userRole !== "5" && !isScreen1024 ? (
            <HamburgerButton
              openSideDrawer={openSideDrawer}
              setOpenSideDrawer={setOpenSideDrawer}
              isActiveNavbar={isActiveNavbar}
              menu={menu}
              handleMenu={handleMenu}
              isAuthenticated={isAuthenticated}
              setAnchorEl={setAnchorEl}
              fullname={fullname}
            />
          ) : (
            <ButtonUser
              isAuthenticated={isAuthenticated}
              setAnchorEl={setAnchorEl}
              fullname={fullname}
              userRole={userRole}
            />
          )}
        </Grid>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List>
            {/* {dashboardMenu.title && (
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleClick(dashboardMenu)}>
                  <Typography variant="body2">{dashboardMenu.title}</Typography>
                </ListItemButton>
              </ListItem>
            )} */}

            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate(`/${version}/user/profile`)}>
                <Typography variant="body2">My Profile</Typography>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleLogout()}>
                <Typography variant="body2">Logout</Typography>
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Grid>
    </AppBar>
  );
};

const HamburgerButton = ({
  setOpenSideDrawer,
  openSideDrawer,
  isActiveNavbar,
  menu,
  handleMenu,
  isAuthenticated,
  setAnchorEl,
  fullname,
}) => {
  return (
    <>
      <IconButton
        size="large"
        variant="contained"
        aria-label="hamburger icon"
        aria-controls="menu-hamburger"
        aria-haspopup="true"
        onClick={() => setOpenSideDrawer(true)}
        color="primary"
      >
        <MenuIcon />
      </IconButton>
      <SideDrawer
        menu={menu}
        isActiveNavbar={isActiveNavbar}
        openSideDrawer={openSideDrawer}
        setOpenSideDrawer={setOpenSideDrawer}
        handleMenu={handleMenu}
        isAuthenticated={isAuthenticated}
        setAnchorEl={setAnchorEl}
        fullname={fullname}
      />
    </>
  );
};

const ButtonUser = ({ isAuthenticated, setAnchorEl, fullname, userRole }) => {
  const theme = useTheme();
  return (
    <>
      {userRole === "5" || !isAuthenticated ? (
        <Button
          size={userRole === "5" ? "small" : "medium"}
          variant="contained"
          color="primary"
          sx={{
            boxShadow: `${shadows[8]}`,
            fontWeight: 700,
            transition: "all linear .4s",
            borderRadius: "5rem",
            "&:hover": {
              backgroundColor: `${theme.palette.primary.main}`,
              transform: "scale(1.05)",
            },
          }}
          onClick={userRole === "5" ? null : () => setOpenLogin(true)}
        >
          Login
        </Button>
      ) : (
        <Button
          size="medium"
          variant="contained"
          color="primary"
          startIcon={<AccountCircleRounded />}
          sx={{
            boxShadow: `${shadows[8]}`,
            p: "0 14px",
            fontWeight: 600,
            transition: "all linear .4s",
            borderRadius: "5rem",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {fullname?.slice(0, 5)}
          {/* {fullname.split(" ")[0]} */}
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isActiveNavbar: state.nav.isActiveNavbar,
  isAuthenticated: state.auth.isAuthenticated,
  navPreview: state.preview.navPreview,
  logoPreview: state.preview.logoPreview,
});

export default connect(mapStateToProps)(Header);
