import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { parsedDate } from "src/functions/parsedDate";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import {
  candidateCheckApply,
  setOpenLogin,
  setSnackbarOpen,
} from "src/redux/actions";
import ModalConfirmApplyJob from "src/components/Modal/ModalConfirmApplyJob";
import localforage from "localforage";

const styleGrid = {
  p: 2,
  backgroundColor: "#ffff",
  m: "auto",
  borderRadius: "0.75rem",
  boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
};
const containerStyle = {
  width: "100%",
  p: 2,
  border: "1.5px solid black",
  borderRadius: "0.75rem",
  position: "relative",
};

const titleStyle = {
  position: "absolute",
  top: "-1rem",
  left: 0,
  ml: "1rem",
  backgroundColor: "#ffff",
};

const DetailVacancy = ({ isAuthenticated }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [tempBio, setTempBio] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [font, setFont] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleSelect = async (v) => {
    if (!isAuthenticated) {
      setOpenLogin(true);
      return;
    }
    setLoadingBtn(true);
    const resTheme = await localforage.getItem("theme");
    setFont(resTheme.font);

    if (v.job_posting_id) {
      const resCheck = await candidateCheckApply({
        job_posting_id: v.job_posting_id,
      });
      if (resCheck.code === 200) {
        setTempBio(resCheck.data);
        setOpenAlert(true);
      } else {
        setSnackbarOpen("error", resCheck.data.message);
      }
      setLoadingBtn(false);
      return;
    }
    setLoadingBtn(false);
  };

  const TitleValue = ({ title, value, style }) => {
    return (
      <Grid item container sx={style}>
        <Grid item xs={3}>
          <Typography>{title}</Typography>
        </Grid>
        <Grid item xs={9}>
          <Stack direction="row" alignItems="center">
            <Typography sx={{ mr: 1 }}>:</Typography>
            <Typography>{value}</Typography>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container maxWidth={"lg"} sx={styleGrid}>
        <Grid item sm={12} sx={{ p: 3 }}>
          <Stack sx={containerStyle}>
            <Typography variant="h5" sx={titleStyle}>
              Informasi Posisi
            </Typography>
            <TitleValue
              title="Nama Posisi"
              value={state.ref_position_group.position_group_name}
              style={{ mt: "0.5rem" }}
            />
            <TitleValue
              title="Tipe Rekrutmen"
              value={state.ref_hiring_type.hiring_type_name}
            />
            <TitleValue
              title="Lokasi Kerja"
              value={state.ref_job_location.job_location_name}
            />
            <TitleValue
              title="Waktu Kerja"
              value={state.ref_working_time.working_time_name}
            />
            <TitleValue
              title="Status Pekerja"
              value={state.ref_employment_status.employment_status_name}
            />
            <TitleValue
              title="Tanggal Penutupan Lamaran"
              value={parsedDate(state.closed_at, "article")}
            />
            {state.is_show_requirement_number && (
              <TitleValue
                title="Jumlah Kebutuhan"
                value={state.employee_requirement_number}
              />
            )}
          </Stack>

          <Stack sx={{ ...containerStyle, my: "3rem" }}>
            <Typography variant="h5" sx={titleStyle}>
              Uraian Pekerjaan
            </Typography>
            <Stack sx={{ mt: "0.5rem", p: 2, pt: 0, fontFamily: font }}>
              {ReactHtmlParser(state.description)}
            </Stack>
          </Stack>

          <Stack sx={{ ...containerStyle, my: "3rem" }}>
            <Typography variant="h5" sx={titleStyle}>
              Persyaratan
            </Typography>
            <Stack sx={{ mt: "0.5rem", p: 2, pt: 0, fontFamily: font }}>
              {ReactHtmlParser(state.requirements)}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"end"}
            sx={{ my: "1rem", pr: 2, width: "100%" }}
          >
            <Button variant="outlined" onClick={() => navigate(-1)}>
              Back
            </Button>
            <Button
              variant="contained"
              disabled={loadingBtn}
              onClick={() => handleSelect(state)}
            >
              Apply
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {openAlert && (
        <ModalConfirmApplyJob
          open={openAlert}
          defaultBio={tempBio}
          jobPostingId={state.job_posting_id}
          positionGroupName={state?.ref_position_group?.position_group_name}
          onClose={() => setOpenAlert(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(DetailVacancy);
