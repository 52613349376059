import React, { useState, useRef, useEffect } from "react";
import { useDrag } from "react-dnd";
import Element from "./Element";
import { Grid, useMediaQuery } from "@mui/material";
import DropZone from "../DropZone";
import { COLUMN } from "../library";
import { loadImage } from "src/functions/utils";
import "../../../../App.css";

const Column = ({
  data,
  rowId,
  rowObjHover,
  setRowObjHover,
  setStateDrawer,
  rowObjEdit,
  setRowObjEdit,
  handleDrop,
  path,
  dropArea,
  setOpenConfirm,
  lengthMainData,
}) => {
  const [bgImg, setBgImg] = useState("");
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: COLUMN,
      id: data.id,
      children: data.children,
      path,
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  useEffect(async () => {
    const resBgImg = await loadImage(data.sectProps?.backgroundImage);
    setBgImg(resBgImg);
  }, []);

  const renderElement = (element, colId, currentPath) => {
    return (
      <Element
        key={element.id}
        data={element}
        colId={colId}
        rowId={rowId}
        rowObjHover={rowObjHover}
        setRowObjHover={setRowObjHover}
        setStateDrawer={setStateDrawer}
        rowObjEdit={rowObjEdit}
        setRowObjEdit={setRowObjEdit}
        handleDrop={handleDrop}
        path={currentPath}
        setOpenConfirm={setOpenConfirm}
      />
    );
  };

  const flexDirectionStyle = () => {
    if (
      (data.props?.alignItems || data.props?.justifyContent) &&
      data.children.length === 1
    ) {
      return "column";
    } else if (
      (data.props?.alignItems || data.props?.justifyContent) &&
      data.children.length > 1
    ) {
      return "row";
    } else return null;
  };

  return (
    <Grid
      ref={drag(ref)}
      item
      container
      md={
        dropArea === path
          ? data.colWidth - 0.5
          : data?.colCn?.indexOf("simpleScrollItem custom-scrollbar x") > -1
          ? data.colWidth - 0.5
          : data.colWidth
      }
      id={data.id}
      className={data.colCn && `col ${data.colCn}`}
      sx={{
        background: bgImg && `no-repeat center url(${bgImg})`,
        backgroundSize: bgImg && "cover",
        display:
          (data.props?.alignItems || data.props?.justifyContent) && "flex",
        flexDirection: flexDirectionStyle(),
        ...data.props,

        border:
          rowObjHover?.colId === data.id || rowObjEdit.colId === data.id
            ? "5px solid cornflowerblue"
            : "",
        boderRadius: rowObjHover?.colId === data.id ? "0.5rem" : "",
        opacity: isDragging ? 0 : 1,
      }}
      onMouseLeave={() => {
        setRowObjHover({});
      }}
    >
      {data.children.map((element, index) => {
        const currentPath = `${path}-${index}`;

        return (
          <React.Fragment key={element.id}>
            <DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length,
              }}
              className="vertical"
              onDrop={handleDrop}
              lengthMainData={lengthMainData}
            />
            {renderElement(element, data.id, currentPath)}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
export default Column;
