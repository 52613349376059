import {
  AUTHENTICATED,
  DEAUTHENTICATED,
  SET_OPEN_LOGIN,
  SET_MIDDLEWARE_APPS,
} from "../events";

const initialState = {
  isAuthenticated: false,
  openLogin: false,
  mdlApps: {
    open: false,
    data: [],
    form: { email: "", password: "" },
    resLogin: null,
  },
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATED: {
      return { ...state, ...action.payload };
    }
    case DEAUTHENTICATED: {
      return { ...state, ...action.payload };
    }
    case SET_OPEN_LOGIN: {
      return { ...state, openLogin: action.payload };
    }
    case SET_MIDDLEWARE_APPS: {
      return { ...state, mdlApps: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default auth;
