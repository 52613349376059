import React from "react";
import {
  Typography,
  useTheme,
  IconButton,
  Box,
  Toolbar,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Button,
  useMediaQuery,
} from "@mui/material";
import { setOpenLogin } from "../redux/actions";
import { ArrowBack, AccountCircleRounded } from "@mui/icons-material";
import shadows from "src/theme/shadow";
import { connect } from "react-redux";

const ButtonUser = ({ isAuthenticated, setAnchorEl, fullname }) => {
  const theme = useTheme();
  const isScreen600 = useMediaQuery("(min-width:600px)");
  return (
    <>
      {!isAuthenticated ? (
        <Button
          size="medium"
          variant="contained"
          color="primary"
          sx={{
            fontWeight: 700,
            transition: "all linear .4s",
            borderRadius: "2.5rem",
            boxShadow: `${shadows[8]}`,
            pl: isScreen600 ? "10%" : "15%",
            pr: isScreen600 ? "10%" : "15%",

            "&:hover": {
              backgroundColor: `${theme.palette.primary.main}`,
              transform: "scale(1.05)",
            },
          }}
          onClick={() => setOpenLogin(true)}
        >
          Login
        </Button>
      ) : (
        <Button
          size="medium"
          variant="contained"
          color="primary"
          startIcon={<AccountCircleRounded />}
          sx={{
            p: "0 14px",
            pl: isScreen600 ? "10%" : "15%",
            pr: isScreen600 ? "10%" : "15%",
            boxShadow: `${shadows[8]}`,
            fontWeight: 600,
            transition: "all linear .4s",
            borderRadius: "2.5rem",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {fullname?.slice(0, 5)}
          {/* {fullname.split(" ")[0]} */}
        </Button>
      )}
    </>
  );
};

const SideDrawer = ({
  openSideDrawer,
  setOpenSideDrawer,
  menu,
  isActiveNavbar,
  handleMenu,
  isAuthenticated,
  setAnchorEl,
  fullname,
}) => {
  const theme = useTheme();
  const isScreen768 = useMediaQuery("(min-width:768px)");
  const drawer = (
    <Box
      sx={{
        width: isScreen768 ? "45vw" : "65vw",
        height: "100vh",
        backgroundColor: "rgb(255,255,255)",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => setOpenSideDrawer(false)}>
          <ArrowBack fontSize="medium" color="primary" />
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        {menu
          .filter((x) => x.title)
          .map((v) => (
            <ListItem key={v.id}>
              <ListItemButton sx={{ padding: 0, margin: 0 }}>
                <Typography
                  component="a"
                  variant="body2"
                  sx={{
                    color:
                      v.title === isActiveNavbar
                        ? `${theme.palette.text.primary}`
                        : `${theme.palette.text.secondary}`,
                    fontWeight: 700,
                    transition: "all linear .4s",
                    p: "0.25rem",
                    pl: "15%",
                    pr: "15%",
                    m: "0 auto",

                    "&:hover": {
                      color: `${theme.palette.text.primary}`,
                      textDecoration: "none",
                      fontWeight: 700,
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleMenu(v)}
                >
                  {v.title}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "0.25rem",
        }}
      >
        <ButtonUser
          isAuthenticated={isAuthenticated}
          setAnchorEl={setAnchorEl}
          fullname={fullname}
        />
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={openSideDrawer}
      onClose={() => setOpenSideDrawer(false)}
    >
      {drawer}
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  // isActiveNavbar: state.nav.isActiveNavbar,
});

export default connect(mapStateToProps)(SideDrawer);
