import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
    width: "600px",
  },
}));

const DialogConfirm = ({ open, handleClose, text, btn, colorBtn }) => {
  return (
    <CustomDialog open={open} onClose={handleClose}>
      <DialogTitle>{btn}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText gutterBottom>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ color: "grey" }}>
          Batal
        </Button>
        <Button color={colorBtn} variant="contained" onClick={handleClose}>
          {btn}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};
export default DialogConfirm;
