import { 
  SET_SNACKBAR_OPEN,
  SET_SNACKBAR_CLOSE,
} from "../events";

const initialState = {
  snackbar: { open: false, type: "", msg: "" },
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR_OPEN: {
      return { ...state, snackbar: action.payload };
    }
    case SET_SNACKBAR_CLOSE:
      return { ...state, snackbar: action.payload }; 
    default:
      return state;
  }
};

export default app;
