import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";

i18n
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: localStorage.getItem("lang") || "id",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: { Bahasa: "English", login: "Login", logout: "Logout" },
      },
      id: {
        translation: { Bahasa: "Bahasa", login: "Login", logout: "Logout" },
      },
    },
  });

export default i18n;
