import React, { useState } from "react";
import {
  Typography,
  useTheme,
  IconButton,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Button,
  useMediaQuery,
  Stack,
  Grid,
} from "@mui/material";
import { setOpenLogin } from "../../redux/actions";
import {
  AccountCircleRounded,
  ArrowForwardIosSharp,
  Close,
  Login,
} from "@mui/icons-material";
import shadows from "src/theme/shadow";
import { useNavigate } from "react-router-dom";
import { version } from "src/configs/globalVariable";

const SideDrawer = ({
  data,
  openSideDrawer,
  setOpenSideDrawer,
  navFilter,
  isAuthenticated,
  setAnchorEl,
  fullname,
  isExpanded,
  setIsExpanded,
  linkObj,
}) => {
  const navigate = useNavigate();
  const isScreen768 = useMediaQuery("(min-width:768px)");
  const drawer = (
    <Stack
      sx={{
        width: isScreen768 ? "45vw" : "100vw",
        height: "100vh",
        backgroundColor: "rgb(255,255,255)",
        position: "relative",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={() => setOpenSideDrawer(false)}>
          <Close fontSize="medium" color="text.primary" />
        </IconButton>
      </Toolbar>
      {data.elCn === "triangle-header" && (
        <>
          {data.children.map((v, i) => (
            <React.Fragment key={i}>
              <List sx={{ p: 2, overFlowY: "auto" }}>
                {!linkObj && (
                  <>
                    {v.mainLink
                      .filter((link) => link.text)
                      .map((link, x) => (
                        <ListItem key={x}>
                          <ListItemButton
                            sx={{
                              borderBottom: "1px solid lightgrey",
                              position: "relative",
                            }}
                            onClick={() => {
                              navFilter(link);
                            }}
                          >
                            <Typography
                              component="a"
                              variant="subtitle1"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {link.text}
                              {link.children?.length > 0 && (
                                <ArrowForwardIosSharp
                                  sx={{
                                    position: "absolute",
                                    right: "0.75rem",
                                    width: "0.75rem",
                                    transition: "all linear .2s",
                                    transform:
                                      isExpanded === link.idGroup
                                        ? "rotate(90deg)"
                                        : "rotate(0deg)",
                                  }}
                                />
                              )}
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </>
                )}

                {linkObj?.children?.length > 0 && (
                  <>
                    <ListItem>
                      <ListItemButton
                        sx={{
                          borderBottom: "1px solid lightgrey",
                        }}
                        onClick={() => {
                          navFilter(linkObj);
                        }}
                      >
                        <Typography
                          component="a"
                          variant="subtitle1"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {linkObj.text}
                          <ArrowForwardIosSharp
                            sx={{
                              position: "absolute",
                              right: "0.75rem",
                              width: "0.75rem",
                              transition: "all linear .2s",
                              transform:
                                isExpanded === linkObj.idGroup
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)",
                            }}
                          />
                        </Typography>
                      </ListItemButton>
                    </ListItem>

                    {linkObj.children
                      .filter((link) => link.text)
                      .map((link, x) => (
                        <ListItem key={x} sx={{ p: 0, pl: 3 }}>
                          <ListItemButton
                            onClick={() => {
                              navigate(
                                `/${version}/user/${link.text}/${linkObj.idGroup}/${link.id}`
                              );
                            }}
                          >
                            <Typography component="a" variant="body1">
                              {link.text}
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </>
                )}
              </List>
              <Stack
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    border: "1px solid lightgrey",
                    p: 2,
                    overflowX: "auto",
                    minWidth: "100%",
                  }}
                >
                  {v.link
                    .filter((link) => link.text)
                    .map((link, x) => (
                      <Grid
                        key={x}
                        item
                        xs="auto"
                        sx={{ p: 1 }}
                        onClick={(e) => navFilter(link, e)}
                      >
                        <Typography
                          component="a"
                          variant="body2"
                          sx={{
                            m: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {link.text}
                          {link.children?.length > 0 && (
                            <ArrowForwardIosSharp
                              sx={{
                                ml: 2,
                                width: "0.75rem",
                                transition: "all linear .2s",
                                transform:
                                  isExpanded === link.idGroup
                                    ? "rotate(270deg)"
                                    : "rotate(0deg)",
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    ))}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ p: 2 }}
                >
                  <ButtonUser
                    fullname={fullname}
                    setAnchorEl={setAnchorEl}
                    isAuthenticated={isAuthenticated}
                  />
                </Stack>
              </Stack>
            </React.Fragment>
          ))}
        </>
      )}
    </Stack>
  );
  // };

  return (
    <Drawer
      anchor="left"
      open={openSideDrawer}
      onClose={() => setOpenSideDrawer(false)}
    >
      {drawer}
      {/* <DrawerChild setLinkGroup={setLinkGroup} linkGroup={linkGroup} /> */}
    </Drawer>
  );
};

const ButtonUser = ({ fullname, setAnchorEl, isAuthenticated }) => {
  const theme = useTheme();
  return (
    <>
      {isAuthenticated ? (
        <Button
          variant="outlined"
          startIcon={<AccountCircleRounded fontSize="medium" />}
          sx={{
            border: "none",
            transition: "all linear .2s",
            color: `${theme.palette.text.primary}`,
            "&:hover": {
              borderRadius: "5rem",
              boxShadow: `${shadows[8]}`,
              border: "none",
            },
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <Typography variant="body1">{fullname}</Typography>
        </Button>
      ) : (
        <Button
          variant="outlined"
          sx={{
            border: "none",
            color: `${theme.palette.text.primary}`,
            transition: "all linear .2s",
            "&:hover": {
              borderRadius: "5rem",
              boxShadow: `${shadows[8]}`,
              border: "none",
            },
          }}
          startIcon={<Login fontSize="medium" />}
          onClick={
            window.location.pathname.indexOf("user") !== -1
              ? () => setOpenLogin(true)
              : null
          }
        >
          <Typography variant="body1">Login</Typography>
        </Button>
      )}
    </>
  );
};
export default SideDrawer;
