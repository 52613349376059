import { SET_IS_ACTIVE_NAVBAR } from "../events";
import { SET_LIB_PAGES } from "../events";
import localforage from "localforage";
import store from "../store";
const { dispatch } = store;

export const setLibPages = async () => {
  const arr = [];
  const resNav = await localforage.getItem("navigation");
  resNav?.forEach((v) => {
    if (v.page === "product") {
      const x = v.link.split("/")[3];
      arr.push(x ? x : v.defaultTitle.toLowerCase());
    } else if (v.page === "dashboard") {
      const x = v.link.split("/")[4];
      arr.push(x ? x : v.defaultTitle.toLowerCase());
    } else if (v.page === "article") {
      arr.push(v.page);
    } else if (v.page === "galery") {
      arr.push(v.page);
    }
  });

  dispatch({
    type: SET_LIB_PAGES,
    payload: arr,
  });
};

export const setIsActiveNavbar = (isActiveNavbar) => {
  dispatch({
    type: SET_IS_ACTIVE_NAVBAR,
    payload: isActiveNavbar,
  });
};
