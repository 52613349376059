import React, { useState } from "react";
import { Grid, Typography, styled, useTheme } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const styleGrid = {
  p: 2,
  backgroundColor: "#ffff",
  m: "auto",
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  boxShadow: "none",
  borderRadius: "0.5rem",
  marginBottom: "1.5rem",
  border: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "1.25rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  paddingBottom: "1rem",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const FAQ = ({ data }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const { i18n } = useTranslation();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Grid container maxWidth={"lg"} sx={styleGrid}>
        <Grid item sm={12} sx={{ p: 3 }}>
          {data.children.map((v, i) => (
            <Accordion
              key={i}
              expanded={expanded === `panel${i + 1}`}
              onChange={handleChange(`panel${i + 1}`)}
            >
              <AccordionSummary>
                <Typography sx={{ color: `${theme.palette.primary.main}` }}>
                  {/* {v.question} */}
                  {i18n.language === "en" ? v.questionEn : v.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {/* {v.answer} */}
                  {i18n.language === "en" ? v.answerEn : v.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default FAQ;
