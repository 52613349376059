import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Avatar,
  Button,
  Divider,
  CardActionArea,
  Card,
  Grid,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  CircularProgress,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import {
  getRefEdu,
  getRefRegion,
  getInformalEducation,
  createInformalEducation,
  updateInformalEducation,
  deleteInformalEducation,
  setSnackbarOpen,
  uploadFileJp,
} from "src/redux/actions";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import CustomDatePicker from "src/components/CustomDatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DownloadDoneRoundedIcon from "@mui/icons-material/DownloadDoneRounded";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const EducationInformal = ({ isEmptyNon, setIsEmptyNon }) => {
  const [loading, setLoading] = useState(true);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [openForm, setOpenForm] = useState("");
  const [cities, setCities] = useState([]);
  const [form, setForm] = useState({});
  const [educations, setEducations] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    fetchRef();
    fetchEdu();
  }, []);

  const fetchRef = async () => {
    const resRef = await Promise.all([getRefRegion({ province: "all" })]);
    setCities(resRef[0].data);
  };

  const fetchEdu = async () => {
    setLoading(true);
    const resEdu = await getInformalEducation();
    setEducations(resEdu.data);
    setIsEmptyNon(resEdu.data?.length > 0 ? false : true);
    setLoading(false);
  };

  const handleDateChange = (val, name) => {
    setForm({ ...form, [name]: val });
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCheck = (e) => {
    setForm({ ...form, is_certificate: e.target.checked });
  };

  const handleEdit = (d) => {
    setForm(d);
    setOpenForm("edit");
  };

  const handleDelete = (d) => {
    setForm(d);
    setAlertOpen(true);
  };

  const handleSubmitDelete = async () => {
    await deleteInformalEducation(form);
    setForm({});
    setSnackbarOpen("success", "Data berhasil dihapus");
    setAlertOpen(false);
    fetchEdu();
  };

  function tambahkanSatuHari(tanggal) {
    let tanggalBaru = new Date(tanggal);
    tanggalBaru.setHours(tanggalBaru.getHours() + 12);
    return tanggalBaru;
  }

  const handleSave = async () => {
    const copyForm = { ...form };
    copyForm.city = form.city?.city;
    copyForm.started_at = tambahkanSatuHari(form.started_at);
    if (openForm === "new") {
      await createInformalEducation(copyForm);
    } else {
      await updateInformalEducation(copyForm);
    }
    setSnackbarOpen("success", "Data disimpan");
    setOpenForm("");
    setForm({});
    fetchEdu();
  };

  const handleUpload = async (files, key) => {
    if (files.length) {
      setLoadingUpload(true);
      const resUpload = await uploadFileJp(files[0]);
      if (typeof resUpload === "string") {
        setLoadingUpload(false);
        setSnackbarOpen("error", resUpload);
        return;
      }
      const copyForm = { ...form };
      copyForm[key] = resUpload.data?.path;
      setForm(copyForm);
      setLoadingUpload(false);
      setSnackbarOpen("success", "Data tersimpan");
    }
  };

  const handleDownload = (link) => {
    const baseURL =
      process.env.NODE_ENV !== "production"
        ? "http://localhost:5000/"
        : window.location.origin + "/";
    var url = baseURL + link;
    var link = document.createElement("a");
    link.href = url;
    link.click();
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        sx={{ m: "4rem 0 1rem 0" }}
      >
        <Typography sx={{ fontSize: "20px", m: "auto 0" }}>
          Keahlian Teknis{" "}
          <span style={{ fontSize: "16px" }}>
            {`(Tulis yang sesuai dengan posisi yang dilamar)`}
          </span>
        </Typography>
        {!openForm && (
          <Button variant="contained" onClick={() => setOpenForm("new")}>
            Tambah
          </Button>
        )}
      </Stack>
      {isEmptyNon && (
        <Typography
          variant="caption"
          sx={{ color: "#ff0000", mb: 4, mt: 4, textAlign: "center" }}
        >
          * required write down at least 1 skill
        </Typography>
      )}
      {educations.map((edu) => (
        <Card
          id={edu.bio_edu_non_id}
          sx={{ backgroundColor: "#FFF", p: 2, borderRadius: "16px" }}
        >
          <Grid container>
            <Grid item sm={12 / 5} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                {edu.name}
              </Typography>
            </Grid>
            <Grid item sm={12 / 5} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {edu.institution_name}
              </Typography>
            </Grid>
            <Grid item sm={12 / 5} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {edu.city?.city}
              </Typography>
            </Grid>
            <Grid item sm={1} sx={{ m: "auto" }}>
              {edu.is_certificate ? (
                <IconButton>
                  <WorkspacePremiumRoundedIcon />
                </IconButton>
              ) : null}
            </Grid>
            <Grid item sm={12 / 5} sx={{ m: "auto" }}>
              <Stack direction="row" justifyContent={"end"} spacing={1}>
                <IconButton onClick={() => handleEdit(edu)}>
                  <EditRoundedIcon />{" "}
                </IconButton>
                <IconButton onClick={() => handleDelete(edu)}>
                  <DeleteRoundedIcon />{" "}
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      ))}
      <Divider sx={{ mt: 2, mb: 2 }} />
      {openForm && (
        <Stack
          sx={{
            border: "1px solid",
            p: "1rem",
            borderRadius: "6px",
            borderColor: "divider",
          }}
        >
          <CustomInput
            label="Nama Keahlian"
            name="name"
            onChange={handleChangeInput}
            value={form.name}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.is_certificate}
                  onChange={handleCheck}
                />
              }
              label="Bersertifikasi"
            />
          </FormGroup>
          {form.is_certificate && (
            <>
              <CustomInput
                label="Penyelenggara"
                onChange={handleChangeInput}
                name="institution_name"
                value={form.institution_name}
              />
              <CustomDatePicker
                label="Tanggal Sertifikat Didapatkan"
                onChange={handleDateChange}
                name="started_at"
                value={form.started_at ? form.started_at : null}
              />
              <Stack direction="row" spacing={2}>
                {form.certificate_url ? (
                  <Button
                    variant="contained"
                    endIcon={<DownloadDoneRoundedIcon />}
                    onClick={() => handleDownload(form.certificate_url)}
                  >
                    Download
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  component="label"
                  disabled={loadingUpload}
                >
                  Upload Sertifikat
                  <input
                    hidden
                    type="file"
                    onChange={(e) =>
                      handleUpload(e.target.files, "certificate_url")
                    }
                  />
                </Button>
              </Stack>
            </>
          )}
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"end"}
            sx={{ my: "1rem" }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setOpenForm("");
                setForm({});
              }}
            >
              Batal
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Simpan
            </Button>
          </Stack>
        </Stack>
      )}

      <CustomDialog open={alertOpen} fullWidth maxWidth="xs">
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent>
          Data <b>{form.name}</b> akan dihapus
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setAlertOpen(false);
              setForm({});
            }}
          >
            Batal
          </Button>
          <Button variant="contained" onClick={handleSubmitDelete}>
            Ya, Hapus
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default EducationInformal;
